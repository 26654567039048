import { useRoutes } from "react-router-dom";

import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";

interface AppRoutesProps {
  maintaining?: boolean;
}

export const AppRoutes = ({ maintaining = false }: AppRoutesProps) => {
  const element = useRoutes(
    maintaining
      ? [...publicRoutes({ maintaining })]
      : [...protectedRoutes, ...publicRoutes({ maintaining })]
  );

  return <>{element}</>;
};
