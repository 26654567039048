import {Subscription} from "../api/admin/types";

export interface Post {
  id: number;
  title: string;
  body: string;
}

export interface IEventNoticeTableData {
  key: number;
  no: React.Key;
  title: string;
  creationDate: string;
  lastModifiedDate: string;
}

export interface IMemberShipTableData {
  key: number;
  no: React.Key;
  id: string;
  joinDate: string;
  membership: string;
  paymentDate: string;
  expirationDate: string;
  paymentNumber: number;
  lastPaymentDate: string;
}

export interface IShippingDetailTableData {
  key: number;
  shippingTime: string;
  title: string;
  linkedPost: string;
  shippingDestination: '전체회원' | '프로' | '라이트';
}

export type LoginFieldType = {
  email?: string;
  password?: string;
  remember?: string;
};

export interface LoginResponseType {
  access_token: string;
}

export type EventNoticeFieldType = {
  title: string;
  content: string;
};

export type PushMessageFieldType = {
  connectionPost: number
  image: File;
  title: string;
  detail: string;
  timeToShip: string;
  placeToShip: string;
  datePicker: any;
};

export type ConnectionPostFieldType = {
  connectionPost: string;
};

export interface ApiResponse<T> {
  data: T;
  error?: string;
  statusCode?: string;
}

export interface UserZippyProperty {
  key: string;
  no: React.Key;
  uid: string;
  registeredDate: string;
  lite: string;
  subscribed: string;
  subscriptionStartDate: string;
  subscriptionExpiryDate: string;
  oneTimeTransactionCount: string;
  dateOfTransaction: string;
  error?: string;
}

export interface UserProperty {
  id: number;
  username: string;
  email: string;
  role: string;
  phone: string;
  type: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  subscription?: Subscription;
  key?: string;
  no?: React.Key;
}

export enum PAYMENT_TYPE {
  PAYMENT_FREE = "1",
  PAYMENT_HOUR = "2",
  PAYMENT_MONTH = "3",
  PAYMENT_YEAR = "4",
}

export interface UserListManagement {
  skip?:number;
  limit?:number;
  total: number;
  totalFreeLite: number;
  totalSubscribed: number;
  memberList: UserZippyProperty[]
}

export interface TotalUserListManagement {
  totalSubscribed: number;
  totalFreeLite: number;
  totalUser: number;
}

export interface Announcement {
  id: number;
  no: React.Key;
  title:string;
  content: string;
  createdAt: string
  updatedAt: string
  image?:File;
}

export interface PushAnnouncement {
  no: React.Key;
  id: number;
  announcementId: number;
  title: string;
  content?: string | null;
  image?: File | null;
  createdAt: string;
  updatedAt: string;
  deliveryTime: string;
  modalDeliveryTime: string;
  group: 'lite' | 'subscribed' | 'all';
  linkedPost?: string;
}


