import { Navigate, Route, Routes } from "react-router-dom";
import {RoutePaths} from "../route-paths";
import LoginUser from "../../pages/User/Login/loginUser";
import RegisterUser from "../../pages/User/Login/registerUser";
import LoginAdmin from "../../pages/Admin/loginAdmin";
import VerifyEmail from "../../pages/User/Login/VerifyEmail";
import ForgotPassword from "../../pages/User/Login/forgotPassword";

export const AuthRouter = () => {
    return (
        <Routes>
            <Route path="/login" element={<LoginUser/>} />
            <Route path="/admin/login" element={<LoginAdmin/>} />
            <Route path="/register" element={<RegisterUser/>} />
            <Route path="/verify-email" element={<VerifyEmail/>} />
            <Route path="/forgot-password" element={<ForgotPassword/>} />
            <Route path="*" element={<Navigate to={RoutePaths.NOT_FOUND} replace />} />
        </Routes>
    );
};
