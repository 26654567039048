
async function fetchAPI<T>(url: string, options: RequestInit = {}, isFormData?:boolean): Promise<T> {
  const token = localStorage.getItem('accessToken');

  const headers = new Headers({
    ...options.headers,
  });

  if (!isFormData) {
    headers.append('Content-Type', `application/json`);
  }

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  const fetchOptions: RequestInit = {
    ...options,
    headers,
  };

  const response = await fetch(`${process.env.REACT_APP_BASE_URL}${url}`, fetchOptions);

  if (!response.ok) {
    const errorData: { message: string, statusCode: number } = await response.json();
    if (errorData.statusCode === 401 && localStorage.getItem('accessToken')) {
      localStorage.removeItem('accessToken');
      window.location.href = '/auth/login';
      throw new Error('Unauthorized: Invalid credentials');
    } else {
      throw new Error(errorData.message || 'Unknown Error');
    }
  }

  if (options.method === 'DELETE' || response.status === 204) {
    return null as any;
  }

  return response.json();
}

export function get<T>(url: string): Promise<T> {
  return fetchAPI<T>(url);
}

export function post<T>(url: string, data: object): Promise<T> {
  return fetchAPI<T>(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function postFormData<T>(url: string, formData: FormData): Promise<T> {
  return fetchAPI<T>(url, {
    method: 'POST',
    body: formData,
  }, true);
}

export function put<T>(url: string, data: object): Promise<T> {
  return fetchAPI<T>(url, {
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export function del<T>(url: string): Promise<T> {
  return fetchAPI<T>(url, {
    method: 'DELETE',
  });
}


