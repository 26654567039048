import React, {useEffect, useState} from 'react';
import './loginStyles.scss';
import {useLocation, useNavigate} from "react-router-dom";
import {Center, Text, createStyles, rem, Button, Stack, Box, TextInput, Flex} from "@mantine/core";
import {cn, findError} from "../../../util/misc";
import {CheckVerifyForgotPass, ResetPassword, SendEmailForgotPass} from "../../../api/auth/login";
import {RoutePaths} from "../../../routers/route-paths";
import {notifyError, notifySuccess} from "../../../util/notification";
import {useTranslation} from "react-i18next";
import {matchesField, useForm} from "@mantine/form";

const useStyles = createStyles((theme) => ({
    mainLayout: {
        textAlign: "center",
        borderRadius: 8,
        color: "white",
        padding: rem(48),
        background: "#333440",
        maxWidth: rem(500),
        [theme.fn.smallerThan("sm")]: {
            padding: rem(20),
        }

    },

    buttonDisable: {
        '&:disabled': {
            backgroundColor: "#4B4C58",
        }
    },
    textInput: {
        label: {
            color: "white",
            "@media (max-width:  88em)": {
                fontSize: 12,
            },
        },
        input: {
            color: "white",
            backgroundColor: "transparent",
            border: "1px solid #4B4C58",
            height: rem(48),
            "@media (max-width:  88em)": {
                height: rem(48),
            },
        },
        description: {
            color: "#4F4F4F",
            "@media (max-width:  88em)": {
                fontSize: 12,
            },
        },
        marginTop: rem(16),
        [theme.fn.smallerThan("xl")]: {
            marginTop: rem(8),
        },
    }
}));

const ForgotPassword = () => {
    const [token, setToken] = useState('');
    const {t} = useTranslation();
    const [statusVerify, setStatusVerify] = useState("");
    const navigate = useNavigate();
    const {classes} = useStyles();
    const [loading, setLoading] = useState(false);
    const [changedPass, setChangedPass] = useState(false);

    const location = useLocation();

    const form = useForm({
        initialValues: {
            email: "",

        },

        validate: {
            email: (value, values) => {
                if (!value) return 'Email address is required.';
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(value)) return 'Invalid email address format.';
                return null;
            }
        },
        validateInputOnChange: true
    });
    const formPassword = useForm({
        initialValues: {
            password: "",
            confirmPassword: "",
        },

        validate: {
            password: (value, values) => {
                if (!value) return 'Password is required.';
                if (value.length < 8 || value.length > 50) return 'Password must be between 8 and 50 characters in length.';
                if (!/(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,50}/.test(value)) return 'Use at least 1 uppercase, numbers & symbols.';
                return null;
            },
            confirmPassword: matchesField("password", "Passwords do not match."),
        },
        validateInputOnChange: true
    });
    const handleSendEmailForgotPass = async () => {
        setLoading(true);
        SendEmailForgotPass(form.values.email).then(() => {
            form.reset();
            setLoading(false);
            notifySuccess({message: t("Email sent successfully!")});
        }).catch(err => {
            notifyError({message: t(findError(err))});
            setLoading(false);
        });
    };

    const handleResetPass = async () => {
        setLoading(true);

        ResetPassword(token, formPassword.values.password).then(() => {
            form.reset();
            setChangedPass(true);
            setLoading(false);
            notifySuccess({message: t("Password has been changed")});
        }).catch(err => {
            notifyError({message: t(findError(err))});
            setLoading(false);
            setChangedPass(false);
        });
    };


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tokenFromUrl = queryParams.get('token');

        if (tokenFromUrl) {
            setToken(tokenFromUrl);
            CheckVerifyForgotPass(tokenFromUrl).then(() => {
                setStatusVerify("success")
            }).catch(() => {
                setStatusVerify("expired")
            })
        }
    }, [location]);

    return (
        <>
            {token &&
                <Center className={cn("vh-100")}>
                    {statusVerify === "success" &&
                        <>
                            {!changedPass && <Center className={cn("vh-100")}>
                                <Stack className={cn(classes.mainLayout, "")}>
                                    <Text fw={700} fz={rem(30)}>Reset Your Password</Text>
                                    <Box sx={{textAlign: "left"}} component="form"
                                         onSubmit={form.onSubmit(handleSendEmailForgotPass)}>
                                        <TextInput
                                            className={classes.textInput}
                                            label="Enter Password"
                                            placeholder="Enter password"
                                            description="Use 8 or more characters with at least 1 uppercase, numbers & symbols"
                                            inputWrapperOrder={['label', 'input', 'description', 'error']}
                                            withAsterisk
                                            c="white"
                                            type="password"
                                            {...formPassword.getInputProps("password")}
                                        ></TextInput>
                                        <TextInput
                                            className={classes.textInput}
                                            label="Confirm Password"
                                            placeholder="Enter password"
                                            c="white"
                                            withAsterisk
                                            type="password"
                                            {...formPassword.getInputProps("confirmPassword")}
                                        />
                                    </Box>
                                    <Button
                                        className={cn(classes.buttonDisable, "")}
                                        fz={rem(18)}
                                        radius={8}
                                        mt={{base: rem(15), xl: rem(30)}}
                                        bg="#5956E9"
                                        h={rem(48)}
                                        loading={loading}
                                        onClick={() => handleResetPass()}
                                    >
                                        Reset password
                                    </Button>
                                </Stack>
                            </Center>}
                            {changedPass && <Center className={cn("vh-100")}>
                                <Stack className={cn(classes.mainLayout, "")}>
                                    <Text fw={700} fz={rem(30)}>Password Has Been Changed</Text>
                                    <Text c={"#8589A1"}>You have successfully changed your password.
                                        Click the button below to direct to home page to log in again.</Text>

                                    <Button
                                        className={cn(classes.buttonDisable, "")}
                                        fz={rem(18)}
                                        radius={8}
                                        mt={{base: rem(15), xl: rem(30)}}
                                        bg="#5956E9"
                                        h={rem(48)}
                                        onClick={() => navigate(RoutePaths.USER_HOME)}
                                    >
                                        HOME PAGE
                                    </Button>
                                </Stack>
                            </Center>}
                        </>
                    }
                    {statusVerify === "expired" &&
                        <Stack className={cn(classes.mainLayout, "")}>
                            <Text fw={700} fz={rem(30)}>Reset Password Link Expired</Text>
                            <Text c={"#8589A1"}>
                                The link to reset your password has expired. Get a new link by trying
                                <span style={{cursor: "pointer", color: "#7B8FF0"}}
                                      onClick={() => {
                                          navigate(RoutePaths.FORGOT_PASSWORD);
                                          window.location.reload()
                                      }
                                      }> forgot password </span>
                                again.</Text>
                        </Stack>
                    }
                </Center>
            }
            {!token &&
                <Center className={cn("vh-100")}>
                    <Stack className={cn(classes.mainLayout, "")}>
                        <Text fw={700} fz={rem(30)}>Forgot Password</Text>
                        <Text c={"#8589A1"}>Please enter your email address to reset your password</Text>
                        <Box sx={{textAlign: "left"}} component="form"
                             onSubmit={form.onSubmit(handleSendEmailForgotPass)}>
                            <TextInput
                                styles={{
                                    label: {color: "white", textAlign: "left", width: "100%"},
                                    input: {
                                        color: "white",
                                        backgroundColor: "transparent",
                                        border: "1px solid #4B4C58",
                                        height: rem(48),
                                        width: "100%"
                                    },
                                    description: {color: "#4F4F4F"}
                                }}
                                label="Email Address"
                                placeholder="Enter email address"
                                withAsterisk
                                mt="md"
                                {...form.getInputProps("email")}
                            ></TextInput>
                        </Box>
                        <Button
                            className={cn(classes.buttonDisable, "")}
                            fz={rem(18)}
                            radius={8}
                            mt={{base: rem(15), xl: rem(30)}}
                            bg="#5956E9"
                            h={rem(48)}
                            loading={loading}
                            onClick={() => handleSendEmailForgotPass()}
                        >
                            Continue
                        </Button>
                        <Center>
                            <Flex fz={rem(12)} fw={400} c={"B6B6B6"}>Return to
                                <Text sx={{cursor: "pointer"}} ml={rem(4)} c="#7B8FF0"
                                      onClick={() => navigate(RoutePaths.AUTH_LOGIN)
                                      }> LOG IN</Text>
                            </Flex>
                        </Center>
                    </Stack>
                </Center>
            }
        </>
    );
};

export default ForgotPassword;
