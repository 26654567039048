import React, {} from "react";
import {Modal, Group, rem, Select} from "@mantine/core";
import {TextInput, Button, Box} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useTranslation} from "react-i18next";
import {notifyError, notifySuccess} from "../../../util/notification";
import {findError} from "../../../util/misc";
import {NewsService} from "../../../api/admin/newAPI";

interface ConfirmationModalAdminProps {
    open: boolean;
    onClose: () => void;
}

function CreateNewsModal({open, onClose}: ConfirmationModalAdminProps) {
    // const theme = useMantineTheme();
    const {t} = useTranslation();

    const form = useForm({
        initialValues: {
            title: "",
            content: "",
            banner: "",
            status: ""
        },

        validate: {
            title: (value, values) => {
                if (!value) return 'Title is required.';
                return null;
            },
            content: (value, values) => {
                if (!value) return 'Content is required.';
                return null;
            },
            banner: (value, values) => {
                if (!value) return 'banner is required.';
                return null;
            },
            status: (value, values) => {
                if (!value) return 'status is required.';
                return null;
            },

        },
        validateInputOnChange: true
    });
    const handleSubmit = async () => {
        const news = {
            title: form.values.title,
            content: form.values.content,
            banner: form.values.banner,
            status: form.values.status,
        };
        NewsService.newsCreate(news).then(res => {
            form.reset();
            notifySuccess({message: t("News has been created.")});
            onClose()
        }).catch(err => {
            notifyError({message: t(findError(err))});
        });
    };
    return (
        <Modal
            opened={open}
            onClose={onClose}
            title={"Create a new admin"}
            centered
        >
            <Box component="form" mx="auto" onSubmit={form.onSubmit(handleSubmit)}>
               <TextInput
                    label="Title"
                    placeholder="Enter title"
                    withAsterisk
                    mt="md"
                    {...form.getInputProps("title")}
                />
                <TextInput
                    label="Content"
                    placeholder="Enter content"
                    withAsterisk
                    mt="md"
                    {...form.getInputProps("content")}
                />
                <TextInput label="Banner" placeholder="Enter image link"
                           withAsterisk {...form.getInputProps("banner")} />
                <Select
                    styles={{
                        rightSection: { pointerEvents: "none" },
                        dropdown: { color: "white" },
                        input: { color: "white", backgroundColor: "#1D222E", borderColor: "#3E4760", height: rem(42) }
                    }}
                    withAsterisk
                    mt="md"
                    c="white"
                    label="Status"
                    placeholder="Free"
                    data={["inactive","active"]}
                    defaultValue="Free"
                    maw={rem(155)}
                    {...form.getInputProps("status")}
                />

                <Group position="right" mt="md">
                    <Button type="submit" bg="#7B8FF0" fz={14} fw={700} w="100%" mb={rem(50)}>
                        {t("Create User")}
                    </Button>
                </Group>
            </Box>
        </Modal>
    );
}

export default CreateNewsModal;
