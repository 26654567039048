import  "./admin.module.css";
import {NewsList} from "../ListPage/NewsList";

export const News = () => {
  return (
    <div className="userManageContainer">
      <NewsList></NewsList>
    </div>
  );
};
