import { Navigate, Route, Routes } from "react-router-dom";

import {RoutePaths} from "../route-paths";
import PaymentPage from "../../pages/User/Payment/PaymentPage";

export const PaymentRouter = () => {
    return (
        <Routes>
            <Route path="/:type" element={<PaymentPage />} />
            <Route path="*" element={<Navigate to={RoutePaths.NOT_FOUND} replace />} />
        </Routes>
    );
};
