// AudioRecordingService.js
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import {generateUUID} from "./azure-service";

class AzureInputService {
  constructor(
    speechLang,
    outputLang,
    isRecording,
    scrollBottom,
    setTranslationList,
    setRecognizer,
    setRecognizing,
    setStartTra,
    revertText
  ) {
    this.keyAzure = process.env.REACT_APP_AZURE_SPEECH_KEY;
    this.region = process.env.REACT_APP_AZURE_SPEECH_REGION;
    this.count = 0;
    this.text = "";
    this.id = "";
    this.scrollBottom = scrollBottom;
    this.isRecording = isRecording;
    this.speechLang = speechLang;
    this.outputLang = outputLang;
    this.setTranslationList = setTranslationList;
    this.setRecognizer = setRecognizer;
    this.setStartTra = setStartTra;
    this.setRecognizing = setRecognizing;
    this.revertText = revertText;

    this.speechConfig = sdk.SpeechConfig.fromSubscription(
      this.keyAzure,
      this.region
    );
    this.speechConfig.speechRecognitionLanguage = speechLang.key;
    this.audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
    this.newRecognizer = new sdk.SpeechRecognizer(
      this.speechConfig,
      this.audioConfig
    );
  }

  startListening() {
    if (this.isRecording) {
      this.newRecognizer.startContinuousRecognitionAsync();
    }
    this.recognizing();
    this.recognized();
    this.setRecognizer(this.newRecognizer);
  }

  recognizing() {
    this.newRecognizer.recognizing = (s, e) => {
      if (e.result.errorDetails) {
        console.error('Error:', e.result.errorDetails);
      }
      this.scrollBottom();
      if (this.count === 0) {
        const translation = {
          recognizedText: e.result.text,
          resultTranslator: null,
          asr: this.speechLang.key,
          tra: this.outputLang.key,
          status: 0,
        };
        this.setTranslationList((prevTranslationList) => {
          let foundTranslation;
          if (prevTranslationList.length > 0) {
            foundTranslation = prevTranslationList.find((t) => t.status === 0);
          }
          return foundTranslation
            ? prevTranslationList
            : [translation, ...prevTranslationList];
        });
      }
      this.count = 1;
      this.text = e.result.text;
      this.setRecognizing(e.result.text);
      this.setRevertRecognizing(this.revertText(e.result.text));
    };
  }

  recognized() {
    this.newRecognizer.recognized = (s, e) => {
      if (e.result.text) {
        this.count = 0;
        this.id = generateUUID();
        const textListen = e.result.text;
        this.setTranslationList((prevTranslationList) => [
          ...prevTranslationList.map((i) => {
            if (!i.status) {
              this.setStartTra(1);
              return {
                ...i,
                recognizedText: textListen,
                revertRecognizedText: this.revertText(textListen),
                resultTranslator: null,
                status: 1,
                id: this.id,
              };
            }
            return i;
          }),
        ]);
      }
    };
  }

}

export default AzureInputService;
