import { ActionIcon, Group, Title, Box, Stack } from "@mantine/core";
import { IconChevronLeft } from "@tabler/icons-react";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import {cn} from "../util/misc";

interface ContentLayoutProps {
  pageTitle?: string;
  children: React.ReactNode;
  renderPageTitle?: () => React.ReactNode;
  renderActions?: () => React.ReactNode;
  allowNavigateBack?: boolean;
  isAdmin?: boolean;
}

export const ContentLayout = ({
  pageTitle,
  children,
  renderActions,
  renderPageTitle,
  allowNavigateBack,
  isAdmin,
}: ContentLayoutProps) => {
  const navigate = useNavigate();

  return (
    <Stack justify={"space-between"} h={"100%"} p="1rem">
      <Box>
        <div className="p-0">
          <div
            className={cn("flex items-center justify-between pb-4 pt-2 sm:h-[85px] sm:px-0", {
              "mb-4": !renderActions && !isAdmin,
              "border-b sm:mb-[30px]": !isAdmin,
            })}
          >
            <Group spacing="sm">
              {allowNavigateBack && (
                <ActionIcon
                  w={30}
                  h={30}
                  radius={50}
                  color="dark"
                  variant="transparent"
                  onClick={() => navigate(-1)}
                >
                  <IconChevronLeft size="1.5rem" stroke={1.5} />
                </ActionIcon>
              )}
              <Title
                color={!isAdmin ? "violet" : "white"}
                fw="bold"
                className="text-base sm:text-[18px]"
              >
                {renderPageTitle ? renderPageTitle() : pageTitle}
              </Title>
            </Group>
            {renderActions && <div className="hidden sm:block">{renderActions()}</div>}
          </div>
          {renderActions && <div className="block p-4 sm:hidden">{renderActions()}</div>}
          <div>{children}</div>
        </div>
      </Box>
    </Stack>
  );
};
