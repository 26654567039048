// AudioRecordingService.js
import {AzureKeyCredential, OpenAIClient} from "@azure/openai";

class OpenAIOutputService {
    constructor(
      speechLang,
      outputLang,
      translationList,
      setTranslationList,
      setStartTra,
      revertText
    ) {
        this.AZURE_OPENAI_ENDPOINT = process.env.REACT_APP_AZURE_OPENAI_ENDPOINT;
        this.AZURE_OPENAI_KEY = process.env.REACT_APP_AZURE_OPENAI_KEY;
        this.MODEL_NAME = process.env.REACT_APP_MODEL_NAME;
        this.speechLang = speechLang;
        this.outputLang = outputLang;
        this.translationList = translationList;
        this.setTranslationList = setTranslationList;
        this.setStartTra = setStartTra;
        this.client = new OpenAIClient(
          this.AZURE_OPENAI_ENDPOINT,
          new AzureKeyCredential(this.AZURE_OPENAI_KEY)
        );
        this.deploymentId = this.MODEL_NAME;
        this.revertText = revertText;
    }

    startAskAI() {
        // eslint-disable-next-line array-callback-return
        this.translationList.map((e) => {
            if (!e.resultTranslator && !e.error) {
                const messages = this.configMessage(e.recognizedText);
                this.client
                  .getChatCompletions(this.deploymentId, messages)
                  .then((res) => {
                      if (res.choices[0].finishReason === "stop") {
                          this.settingResult(
                            e,
                            this.outputLang.key === "zh-TW"
                              ? res.choices[0].message.content
                              : res.choices[0].message.content
                          );
                      } else if (res.choices[0].finishReason === "content_filter") {
                          this.settingResult(
                            e,
                            `WARNING: Unable to translate due to policy violation.`
                          );
                      }
                  })
                  .catch((error) => {
                      this.settingResult(
                        e,
                        `WARNING: Unable to translate due to policy violation.`
                      );
                  });
            }
            if (e.error) {
                this.settingResult(e, null);
            }
        });
    }

    settingResult(e, result) {
        this.setTranslationList((prevTranslationList) => [
            ...prevTranslationList.map((tran) => {
                if (tran.id === e.id && !tran.resultTranslator && tran.status === 1) {
                    this.setStartTra(0);
                    return {
                        ...tran,
                        resultTranslator: result,
                        revertResultTranslator:
                          result !== null
                            ? result.includes("WARNING:")
                              ? result
                              : this.revertText(result)
                            : null,
                        status: 1,
                    };
                }
                return tran;
            }),
        ]);
    }

    configMessage(textListen) {
        return [
            {
                role: "system",
                content: `You are a professional assistant. 
                You are a support expert answering what user saying. 
                The user will ask you in ${this.speechLang.label} and you will answer back in ${this.outputLang.label}.
                Don't translate my answer, try to answer what i'm saying!
                `,
            },
            {
                role: "user",
                content: `Please read my question in ${this.speechLang.label} and answer in ${this.outputLang.label}.
                ---
                PARAGRAPH
                ${textListen}
                ---
                ANSWER
                `,
            },
        ];
    }
}

export default OpenAIOutputService;
