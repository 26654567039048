import React, { } from "react";
import { Modal, Group, PasswordInput, rem, Select } from "@mantine/core";
import { TextInput, Button, Box } from "@mantine/core";
import { useForm, isNotEmpty, matchesField } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { UserService } from "../../../api/admin/userAPI";
import {notifyError, notifySuccess} from "../../../util/notification";
import {findError} from "../../../util/misc";

interface ConfirmationModalAdminProps {
  open: boolean;
  onClose: () => void;
}


function CreateAdminModal({ open, onClose }: ConfirmationModalAdminProps) {
  // const theme = useMantineTheme();
  const data = [
    {value: "1", label: "Free"},
    {value: "2", label: "Hour"},
    {value: "3", label: "Month"},
    {value: "4", label: "Year"}
  ]
  const { t } = useTranslation();

  const form = useForm({
    initialValues: {
      username: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      role: "normal",
      pricing: "Free"
    },

    validate: {
      username: (value, values) => {
        if (!value) return 'Username is required.';
        if (value.length < 3 || value.length > 25) return 'Username must be between 3 and 25 characters in length.';
        if (/[A-Z]/.test(value)) return 'Username cannot contain uppercase letters.';
        if (!/^[a-zA-Z0-9_-]+$/.test(value)) return 'Username can only contain letters, numbers, underscores, and hyphens.';
        if (/^\d/.test(value)) return 'Username must start with a letter.';
        return null;
      },
      email: (value, values) => {
        if (!value) return 'Email address is required.';
        if (!(/^\S+@\S+$/.test(value))) return 'Invalid email format.';
        return null;
      },
      phone: (value, values) => {
        if (!value) return 'Phone is required.';
        return null;
      },
      password: (value, values) => {
        if (!value) return 'Password is required.';
        if (value.length < 8 || value.length > 50) return 'Password must be between 8 and 50 characters in length.';
        if (!/(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,50}/.test(value)) return 'Use at least 1 uppercase, numbers & symbols.';
        return null;
      },
      confirmPassword: matchesField("password", "Passwords do not match."),
      role: isNotEmpty()
    },
    validateInputOnChange: true
  });
  const handleSubmit = async () => {
    const user = {
      username: form.values.username,
      email: form.values.email,
      password: form.values.password,
      phone: form.values.phone,
      pricing: form.values.pricing,
      status: "active"
    };
    UserService.userCreate(user).then(res => {
      form.reset();
      notifySuccess({ message: t("New account has been created.") });
      onClose()
    }).catch(err => {
      notifyError({ message: t(findError(err)) });
    });
  };
  return (
    <Modal
      opened={open}
      onClose={onClose}
      title={"Create a new admin"}
      centered
    >
      <Box component="form" mx="auto" onSubmit={form.onSubmit(handleSubmit)}>
        <TextInput label="Username" placeholder="Enter username" withAsterisk {...form.getInputProps("username")} />
        <TextInput
          label="Email Address"
          placeholder="Enter email address"
          withAsterisk
          mt="md"
          {...form.getInputProps("email")}
        />
        <TextInput
            label="Phone number"
            placeholder="Enter your phone"
            withAsterisk
            mt="md"
            {...form.getInputProps("phone")}
        />
        <PasswordInput
          label="Enter Password"
          placeholder="Enter password"
          description="Use 8 or more characters with at least 1 uppercase, numbers & symbols"
          inputWrapperOrder={['label', 'input', 'description', 'error']}
          withAsterisk
          mt="md"
          {...form.getInputProps("password")}
        />
        <PasswordInput
          label="Confirm Password"
          placeholder="Enter password"
          withAsterisk
          mt="md"
          {...form.getInputProps("confirmPassword")}
        />
        <Select
            styles={{
              rightSection: { pointerEvents: "none" },
              dropdown: { color: "white" },
              input: { color: "white", backgroundColor: "#1D222E", borderColor: "#3E4760", height: rem(42) }
            }}
            withAsterisk
            mt="md"
            c="white"
            label="Pricing"
            placeholder="Free"
            data={data}
            defaultValue="Free"
            maw={rem(155)}
            {...form.getInputProps("pricing")}
        />
        {/*<Select*/}
        {/*  styles={{*/}
        {/*    rightSection: { pointerEvents: "none" },*/}
        {/*    dropdown: { color: "white" },*/}
        {/*    input: { color: "white", backgroundColor: "#1D222E", borderColor: "#3E4760", height: rem(42) }*/}
        {/*  }}*/}
        {/*  withAsterisk*/}
        {/*  mt="md"*/}
        {/*  c="white"*/}
        {/*  label="Type"*/}
        {/*  placeholder="normal"*/}
        {/*  data={["Free","Month","Year","Admin", "normal"]}*/}
        {/*  disabled={true}*/}
        {/*  maw={rem(155)}*/}
        {/*  {...form.getInputProps("role")}*/}
        {/*/>*/}

        <Group position="right" mt="md">
          <Button type="submit" bg="#7B8FF0" fz={14} fw={700} w="100%" mb={rem(50)}>
            {t("Create User")}
          </Button>
        </Group>
      </Box>
    </Modal>
  );
}

export default CreateAdminModal;
