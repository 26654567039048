import { Navigate, Route, Routes } from "react-router-dom";

import {RoutePaths} from "../route-paths";
import Dashboard from "../../pages/dashboard/dashboard";

export const DashboardRouter = () => {
    return (
        <Routes>
            <Route path="/sindeso-ai" element={<Dashboard />} />
            <Route path="*" element={<Navigate to={RoutePaths.NOT_FOUND} replace />} />
        </Routes>
    );
};
