import {Button, Flex, Group, Pagination, rem, Select, TextInput} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {IconChevronDown, IconSearch} from "@tabler/icons-react";
import {UserService} from "../../../api/admin/userAPI";
import CustomAdminTable from "../../../commons/DataListPaginationAdmin/CustomTableAdmin";
import ConfirmationModalAdmin from "../modalAdmin/ConfirmationModalAdmin";
import CreateAdminModal from "../modalAdmin/CreateAdminModal";
import {User} from "../../../api/admin/types";
import {notifyError, notifySuccess} from "../../../util/notification";
import {findError} from "../../../util/misc";
import {PAYMENT_TYPE} from "../../../types";

export const UserList = () => {
    const {t} = useTranslation();
    const [userData, setUserData] = useState<User[]>([]);
    const [queryParams, setQueryParams] = useState(
        {page: 1, limit: 10, status: "active", type: "", search: ""}
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalCreateOpen, setModalCreateOpen] = useState(false);
    const [userSelection, setUserSelection] = useState<User>();
    const [updated, setUpdated] = useState<boolean>(false);
    const [modalAction, setModalAction] = useState({
        title: "Delete User",
        description: "This users will be deleted. \n" +
            "Please confirm if you want to continue.",
        cancelText: "Cancel",
        confirmText: "Confirm",
        type: "Delete"
    });

    const columnUser = [
        {title: "Username", dataIndex: "username", width: "15%"},
        {title: "Email", dataIndex: "email", width: "20%"},
        {title: "Date Joined", dataIndex: "createdAt", width: "15%"},
        {title: "Roles", dataIndex: "role", width: "10%"},
        {title: "Type", dataIndex: "type", width: "10%"},
        {title: "Status Payment", dataIndex: "statusPayment", width: "10%"}
    ];

    const renderRowButton = (record: any) => (
        <>
            {record.subscription && (
                <Button variant={"subtle"} style={{color: "#7B8FF0"}}
                        onClick={() => handleEdit(record)}>Active</Button>
            )}
            {record.role !== "admin" && (
                <Button variant={"subtle"} style={{color: "#7B8FF0"}}
                        onClick={() => handleDelete(record)}>Delete</Button>
            )}
        </>
    );

    useEffect(() => {
        setQueryParams(prevParams => ({
            ...prevParams,
            search: searchText
        }));
    }, [searchText]);


    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        setQueryParams(prevParams => ({
            ...prevParams,
            page: page
        }));
    };

    const handleStatusChange = (status: string) => {
        setQueryParams(prevParams => ({
            ...prevParams,
            status: status === "All" ? "" : status.toLowerCase()
        }));
    };

    // const handleRoleChange = (role: string) => {
    //   setQueryParams(prevParams => ({
    //     ...prevParams,
    //     type: role === "All" ? "" : role === 'User' ? '1' : '2'
    //   }));
    // };


    useEffect(() => {
        UserService.userList(queryParams).then(data => {
            const userList = data.data.items.map((user, index) => {
                user.key = index;
                user.createdAt = new Date(user.createdAt).toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true
                });
                user.statusPayment = user.subscription?.status;
                user.type = `${user?.subscription?.pricingId}` === PAYMENT_TYPE.PAYMENT_FREE ? "Free" :
                    `${user?.subscription?.pricingId}` === PAYMENT_TYPE.PAYMENT_MONTH ? "Month" :
                        `${user?.subscription?.pricingId}` === PAYMENT_TYPE.PAYMENT_HOUR ? "Hour" : "Year";
                setUpdated(false);
                return user;
            });
            setUserData(userList);
            setCurrentPage(data.data.metaData.currentPage);
            setPageSize(data.data.metaData.totalPages);
        });
    }, [queryParams, updated, isModalCreateOpen]);

    const handleEdit = (record: any, type?: string) => {
        setModalAction({
            title: record.status === "Active" ? "Deactivate User" : "Activate User",
            description: "This user will be changed. \n" +
                "Please confirm if you want to continue.",
            cancelText: "Cancel",
            confirmText: "Confirm",
            type: "Update"
        });
        setIsModalOpen(true);
        setUserSelection(record);
    };

    const handleDelete = (record: any) => {
        setModalAction({
            title: "Delete User",
            description: "This users will be deleted. \n" +
                "Please confirm if you want to continue.",
            cancelText: "Cancel",
            confirmText: "Confirm",
            type: "Delete"
        });
        setIsModalOpen(true);
        setUserSelection(record);
    };

    const handleConfirm = () => {
        switch (modalAction.type) {
            case "Delete":
                UserService.userDelete(userSelection?.id).then(res => {
                    setUpdated(true);
                    notifySuccess({message: t("User has been deleted.")});
                }).catch(err => {
                    setUpdated(false);
                    notifyError({message: t(findError(err))});
                });
                break;
            default:
                const body =  {
                    status: userSelection?.subscription?.status === "inactive" ? "active" : "inactive",
                    pricingId: userSelection?.subscription?.pricingId
                }
                UserService.userUpdateStatus(userSelection?.subscription?.id, body).then(res => {
                    setUpdated(true);
                    notifySuccess({message: t(userSelection?.status === "Active" ? "User has been deactivated." : "User has been activated.")});
                }).catch(err => {
                    setUpdated(false);
                    notifyError({message: t(findError(err))});
                });
                break;
        }

        setIsModalOpen(false);
    };

    return (
        <>
            <Flex
                justify="space-between"
                align="self-end"
                my={16}
            >
                <Flex>
                    <Select
                        label="Status"
                        placeholder="Pick one"
                        rightSection={<IconChevronDown size="1rem"/>}
                        rightSectionWidth={30}
                        styles={{
                            rightSection: {pointerEvents: "none"},
                            label: {color: "white"},
                            dropdown: {color: "white"},
                            input: {color: "white", backgroundColor: "#1D222E", borderColor: "#3E4760", height: rem(42)}
                        }}
                        maw={rem(110)}
                        height={rem(42)}
                        mr={8}
                        c="white"
                        defaultValue="All"
                        data={["All", "Active", "Inactive"]}
                        onChange={handleStatusChange}
                    />
                    {/*<Select*/}
                    {/*  maw={rem(110)}*/}
                    {/*  height={rem(42)}*/}
                    {/*  mr={8}*/}
                    {/*  rightSectionWidth={30}*/}
                    {/*  styles={{*/}
                    {/*    rightSection: { pointerEvents: "none" },*/}
                    {/*    label: { color: "white" },*/}
                    {/*    dropdown: { color: "white" },*/}
                    {/*    input: { color: "white", backgroundColor: "#1D222E", borderColor: "#3E4760", height: rem(42) }*/}
                    {/*  }}*/}
                    {/*  c="white"*/}
                    {/*  label="Type"*/}
                    {/*  placeholder="Pick one"*/}
                    {/*  rightSection={<IconChevronDown size="1rem" />}*/}
                    {/*  defaultValue="All"*/}
                    {/*  data={["All", "User", "Admin"]}*/}
                    {/*  onChange={handleRoleChange}*/}
                    {/*/>*/}
                    <TextInput
                        icon={<IconSearch size="0.8rem"/>}
                        label="Search"
                        placeholder="Search by email or username"
                        height={42}
                        miw={251}
                        c="white"
                        styles={{
                            label: {color: "white"},
                            input: {
                                color: "white",
                                backgroundColor: "transparent",
                                borderColor: "#3E4760",
                                height: rem(42)
                            },
                            description: {color: "#4F4F4F"}
                        }}
                        value={searchText}
                        onChange={(event) => setSearchText(event.currentTarget.value)}
                    />
                </Flex>
                <Button bg="#7B8FF0" fz={14} fw={700} onClick={() => setModalCreateOpen(true)}>
                    {t("Create User")}
                </Button>
            </Flex>
            <CustomAdminTable columns={columnUser as any} data={userData as any}
                              renderRowButton={renderRowButton}></CustomAdminTable>
            {pageSize > 1 && (
                <Pagination.Root
                    total={pageSize}
                    defaultValue={1}
                    value={currentPage}
                    onChange={(page) => handlePageChange(page)}
                >
                    <Group spacing={pageSize} position="center" mt={rem(16)}>
                        {pageSize > 1 && <Pagination.First/>}
                        {pageSize > 1 && <Pagination.Previous/>}
                        <Pagination.Items/>
                        {pageSize > 1 && <Pagination.Next/>}
                        {pageSize > 1 && <Pagination.Last/>}
                    </Group>
                </Pagination.Root>
            )}

            <ConfirmationModalAdmin
                confirmText={modalAction.confirmText}
                cancelText={modalAction.cancelText}
                description={modalAction.description}
                title={modalAction.title}
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)} onConfirm={handleConfirm}
            />
            <CreateAdminModal open={isModalCreateOpen} onClose={() => setModalCreateOpen(false)}/>
        </>
    );
};
