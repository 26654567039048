import { NavigateFunction } from 'react-router-dom';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import moment from 'moment';
import {Translation} from "../pages/dashboard/sindesoAI";

export const handleNavigate = (navigate: NavigateFunction, path: string | undefined) => {
  navigate(path || '/');
};

export const getFirstPathURL = (path: string) => {
  return `/${path.split('/')[1]}`;
};

export const exportToExcel = (tableData: Translation[], fileName = 'table-data') => {
  const data = tableData.map((item, index) => {
    return {
      no: index,
      SpeechText: item.recognizedText,
      translateOrAnswerText: item.resultTranslator,
    };
  });

  // const duplicateItem = { ...data[0] };
  // data.unshift(duplicateItem);
  const ws = XLSX.utils.json_to_sheet(data);

  ws['A1'].v = 'No';
  ws['B1'].v = 'SpeechText';
  ws['C1'].v = 'Result';


  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  // ANCHOR - Buffer
  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

  // ANCHOR - Create a Blob
  const blob = new Blob([wbout], { type: 'application/octet-stream' });

  // ANCHOR - Save the file
  saveAs(blob, `${fileName}.xlsx`);
};

export const dateFormatYearMonthDay = (date: string) => {
  return date ? moment(date).format('YY-MM-DD') : '-';
};

export const dateFormatCustomize = (date: string, formatType: string) => {
  return moment(date).format(formatType);
};
