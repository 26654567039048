import React, {useEffect} from 'react';
import {
    Box,
    Button,
    Center,
    Container,
    Flex,
    rem,
    Text,
    TextInput,
    Title,
    Divider,
    Image,
    createStyles
} from "@mantine/core";
import {useForm} from "@mantine/form";
import {UserService} from "../../../api/admin/userAPI";
import './loginStyles.scss';
import Background from '../../../images/loginUserbackground.png'
import {matchesField} from "@mantine/form";
import {RoutePaths} from "../../../routers/route-paths";
import {useNavigate} from "react-router-dom";
import {notifyError, notifySuccess} from "../../../util/notification";
import {findError} from "../../../util/misc";
import {useTranslation} from "react-i18next";
import {handleNavigate} from "../../../utils";
import logo from "../../../images/sindesoLogo.png";

const useStyles = createStyles((theme) => ({
    registerContainer: {
        paddingBottom: "40px",
        [theme.fn.smallerThan("xl")]: {
            padding: `0 ${rem(60)}`
        },
        [theme.fn.smallerThan("xs")]: {
            padding: `0 ${rem(24)}`
        },
    },

    titleRegister: {
        fontSize: rem(78),
        [theme.fn.smallerThan("xl")]: {
            fontSize: rem(40),
            marginBottom: rem(20)
        },
        [theme.fn.smallerThan("xs")]: {
            fontSize: rem(40),
            marginBottom: rem(12)
        },
    },

    textInput: {
        label: {
            color: "white",
            "@media (max-width:  88em)": {
                fontSize: 12,
            },
        },
        input: {
            color: "white",
            backgroundColor: "#261046",
            border: "none",
            height: rem(42),
            "@media (max-width:  88em)": {
                height: rem(36),
            },
        },
        description: {
            color: "#4F4F4F",
            "@media (max-width:  88em)": {
                fontSize: 12,
            },
        },
        marginTop: rem(16),
        [theme.fn.smallerThan("xl")]: {
            marginTop: rem(8),
        },
    }

}));

const RegisterUser = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {classes} = useStyles();
    const form = useForm({
        initialValues: {
            email: "",
            password: "",
            phone: "",
            confirmPassword: "",
            name: ""
        },

        validate: {
            email: (value, values) => {
                if (!value) return 'Email address is required.';
                return null;
            },
            password: (value, values) => {
                if (!value) return 'Password is required.';
                if (value.length < 8 || value.length > 50) return 'Password must be between 8 and 50 characters in length.';
                if (!/(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,50}/.test(value)) return 'Use at least 1 uppercase, numbers & symbols.';
                return null;
            },
            phone: (value, values) => {
                if (!value) return 'Phone is required.';
                return null;
            },
            confirmPassword: matchesField("password", "Passwords do not match."),
            name: (value, values) => {
                if (!value) return 'Username is required.';
                if (value.length < 3 || value.length > 25) return 'Username must be between 3 and 25 characters in length.';
                if (/[A-Z]/.test(value)) return 'Username cannot contain uppercase letters.';
                if (!/^[a-zA-Z0-9_-]+$/.test(value)) return 'Username can only contain letters, numbers, underscores, and hyphens.';
                if (/^\d/.test(value)) return 'Username must start with a letter.';
                return null;
            },
        },
        validateInputOnChange: true
    });
    const handleSubmit = async () => {
        const user = {
            email: form.values.email,
            username: form.values.name,
            password: form.values.password,
            phone: form.values.name
        };
        UserService.userUserAccount(user).then(res => {
            form.reset();
            notifySuccess({message: t("Create successfully. ")});
            handleNavigate(navigate, RoutePaths.AUTH_VERIFY_EMAIL);
        }).catch(err => {
            notifyError({message: t(findError(err))});
        });
    };
    useEffect(() => {
    }, []);

    return (
        <>
            <Flex bg="#06010E"
                  style={{backgroundImage: `url(${Background})`, overflowX: "hidden", backgroundRepeat: "no-repeat"}}
                  className="background-login vh-100" c="white"
                  mih="100vh" sx={{position: "relative", justifyContent: "center"}} justify={{sm: 'right'}}>
                <Container sx={{position: "absolute", left: "24px", bottom: "24px", display: "none"}}
                           display={{sm: "block"}} fz={rem(51)} fw={700}>
                    <Title>JOIN US TO</Title>
                    <Text className="text-world" sx={{}} bg="linear-gradient(to bottom, #501794, #AE69FF)">UNLOCK THE
                        WORLD</Text>
                </Container>
                <Flex onClick={() => handleNavigate(navigate, RoutePaths.USER_HOME)} align={"center"}
                      sx={{position: "absolute", left: "24px", top: "24px", cursor: "pointer"}} fz={rem(51)} fw={700}>
                    <Image src={logo} width={40} height={40} mr={rem(12)}/>
                    <Text c={"white"} className="d-none d-sm-flex">Sindeso Tech</Text>
                </Flex>


                <Center sx={{alignItems: "center", "@media screen and (min-width: 48em)": {alignItems: "center"}}}
                        bg={{sm: "linear-gradient(to right, #06010E 0%, #06010E 100%)", xs: `url(${Background})`}}
                        w={{xs: '100%', sm: '35%'}}>
                    <Container className={classes.registerContainer} sx={{paddingBottom: "40px"}} pb={{sm: 0}}>
                        <Title className={classes.titleRegister}>Register</Title>
                        <Box component="form" mx="auto" onSubmit={form.onSubmit(handleSubmit)}>
                            <TextInput
                                className={classes.textInput}
                                label="Email Address"
                                placeholder="Enter email address"
                                withAsterisk
                                {...form.getInputProps("email")}
                            ></TextInput>
                            <Flex gap={"md"}>
                                <TextInput
                                    className={classes.textInput}
                                    label="Username"
                                    placeholder="Enter username"
                                    c="white"
                                    withAsterisk
                                    {...form.getInputProps("name")}
                                />
                                <TextInput
                                    className={classes.textInput}
                                    label="Phone"
                                    placeholder="Enter your phone"
                                    c="white"
                                    withAsterisk
                                    {...form.getInputProps("phone")}
                                />
                            </Flex>
                            <TextInput
                                className={classes.textInput}
                                label="Enter Password"
                                placeholder="Enter password"
                                description="Use 8 or more characters with at least 1 uppercase, numbers & symbols"
                                inputWrapperOrder={['label', 'input', 'description', 'error']}
                                withAsterisk
                                c="white"
                                type="password"
                                {...form.getInputProps("password")}
                            ></TextInput>
                            <TextInput
                                className={classes.textInput}
                                label="Confirm Password"
                                placeholder="Enter password"
                                c="white"
                                withAsterisk
                                type="password"
                                {...form.getInputProps("confirmPassword")}
                            />
                            <Button type="submit" mt={rem(30)} mih={rem(50)} sx={{border: "none", borderRadius: '10px'}}
                                    fz={rem(23)} w="100%"
                                    bg={"linear-gradient(to right, #501794 0%, #3E70A1 100%)"}>Sign up</Button>
                        </Box>

                        <Divider my={rem(30)}></Divider>
                        <Flex fz={rem(10)} fw={600} c={"B6B6B6"}>HAVE AN ACCOUNT?
                            <Text sx={{cursor: "pointer"}} onClick={() => navigate(RoutePaths.AUTH_LOGIN)
                            } ml={rem(4)} c="#9D5CE9"> LOGIN</Text>
                        </Flex>
                    </Container>
                </Center>
            </Flex>
        </>
    );
};

export default RegisterUser;
