import './App.css';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {AppProvider} from "./providers/app";
import {AppRoutes} from "./routers";

function App() {

    return (
        <AppProvider>
            <AppRoutes />
        </AppProvider>
  );
}

export default App;
