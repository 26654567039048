import create from 'zustand';

interface User {
    email: string;
    plan: string;
}

interface UserAdminStore {
    user: User;
    setUser: (userData: User) => void;
}

const userStore = create<UserAdminStore>((set) => ({
    user: {
        email: localStorage.getItem('email') || '',
        plan: ''
    },
    setUser: (userData) => set({ user: userData }),
}));

export default userStore;