export enum RoutePaths {
  /* Start: App Route Paths */
  ROOT = "/",
  NOT_FOUND = "/404",
  MAINTENANCE = "/maintenance",

  AUTH_LOGIN = "/auth/login",
  AUTH_REGISTER = "/auth/register",
  AUTH_VERIFY_EMAIL = "/auth/verify-email",
  FORGOT_PASSWORD = "/auth/forgot-password",
  PROFILE = "/profile",

  /* End: App Route Paths */
  /* Start: Development Route Paths */
  DASHBOARD = "/dashboard/control/sindeso-ai",

  DEVELOPMENT_USER_PROFILE = "/admin/profile",
  /* End: Development Route Paths */

  USER_HOME = "/user",
  USER_SERVICES = "/user/services",
  USER_NEWS = "/user/news",
  USER_CONTACT = "/user/contact",
  USER_SOLUTIONS = "/user/solution",

  PAYMENT_PAGE = "/payment/method",

  ADMIN_USER_LIST = "/admin/user/user-list",
  ADMIN_POST_LIST = "/admin/user/news-list",
  ADMIN_REPORTED_POST_LIST = "/admin/user/contacts-list",
  ADMIN_USER_LIST_DETAIL = "/admin/user/user-list/:userId",
}
