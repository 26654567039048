import React, {useEffect, useState} from 'react';
import {
    Button,
    Center,
    createStyles,
    Flex,
    Grid,
    rem,
    Stack,
    Text,
    Title,
    List
} from "@mantine/core";
import {cn, findError} from "../../../util/misc";
import {
    IconEye,
    IconHeartFilled, IconSend
} from "@tabler/icons-react";
import {NewsService} from "../../../api/admin/newAPI";
import {useParams} from "react-router-dom";
import {News} from "../../../api/admin/types";
import {notifyError} from "../../../util/notification";
import {useTranslation} from "react-i18next";

const useStyles = createStyles((theme) => ({
    container: {
        padding: `${rem(100)} ${rem(100)} ${rem(100)} ${rem(100)}`,
        [theme.fn.smallerThan("sm")]: {
            padding: `${rem(50)} ${rem(16)} ${rem(50)} ${rem(16)}`,
        }
    },

    mainContentLeft: {
        border: "1px solid #262626",
        borderLeft: "none",
        borderBottom: "none"
    },

    borderTop: {
        borderTop: "1px solid #262626",
    },

    mainContentRight: {
        borderTop: "1px solid #262626",
    },

    title: {
        color: "white"
    },


    text: {
        color: "#98989A"
    }

}));

const NewsDetailPage = () => {
    const {classes} = useStyles();
    const { id } = useParams();
    const [newsData, setNewsData] = useState<News>();
    const { t } = useTranslation();


    useEffect(() => {
        NewsService.newDetail(id).then(res => {
            setNewsData(res.data);
        }).catch(err => {
            notifyError({ message: t(findError(err)) });
        });
    }, [id, t]);

    return (
        <Stack c={"white"} bg={"black"} className={cn(classes.container, 'user-home-container w-100')}>
            <Title>{newsData?.title}</Title>
            <img src={newsData?.banner || "/defaultImage.png"} alt="defaultimage" className="w-100"
                 style={{objectFit: "cover", maxHeight: 600}}/>
            <Grid mt={rem(40)} gutter={rem(0)} w="100%" columns={12} c="white">
                <Grid.Col className={cn(classes.mainContentLeft, '')} sm={8} span={12} order={2} orderSm={1}
                          p={rem(20)}>
                    <Center h={rem(200)}>
                        <Stack spacing={0} w={"100%"}>
                            <Title mb={rem(20)} size="md" className={cn(classes.title, '')}>
                                Introduction
                            </Title>
                            <br></br>
                            <Text className={cn(classes.text, '')}>
                                {newsData?.title}
                            </Text>
                        </Stack>
                    </Center>
                    <Stack className={cn(classes.borderTop, '')} mt={rem(40)} pt={rem(40)}>
                        <Title mb={rem(20)} fz={rem(30)} className={cn(classes.title, '')}>
                            Content
                        </Title>
                        <Text className={cn(classes.text, '')}>
                            {newsData?.content}
                        </Text>
                    </Stack>

                </Grid.Col>
                <Grid.Col className={cn(classes.mainContentRight, '')} sm={4} span={12} order={1} orderSm={2}>
                    <Center w={"100%"} h={rem(100)}>
                        <Flex align={"center"} justify={"space-between"}>
                            <Button px={rem(10)} radius={rem(20)} variant={"default"}>
                                <IconHeartFilled color={"#FF5500"}/>&nbsp;  {newsData?.likes}
                            </Button>

                            <Button ml={rem(14)} px={rem(10)} radius={rem(20)} variant={"default"}>
                                <IconEye/>&nbsp;  {newsData?.views}
                            </Button>

                            <Button ml={rem(14)} px={rem(10)} radius={rem(20)} variant={"default"}>
                                <IconSend size={rem(16)}/>&nbsp;9
                            </Button>
                        </Flex>
                    </Center>
                    <Stack className={cn(classes.borderTop, 'py-5 px-5')}>
                        <Grid gutter={rem(20)} w="100%" columns={12} mb={rem(30)}>
                            <Grid.Col span={6}>
                                <Text c={"#98989A"}>Publication Date</Text>
                                <Text c={"white"}> {newsData?.createdAt}</Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text c={"#98989A"}>Category</Text>
                                <Text c={"white"}>Technologies</Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text c={"#98989A"}>Reading Time</Text>
                                <Text c={"white"}>10 Min</Text>
                            </Grid.Col>
                            <Grid.Col span={6}>
                                <Text c={"#98989A"}>Author Name</Text>
                                <Text c={"white"}>Sindeso Tech</Text>
                            </Grid.Col>

                        </Grid>
                        <Stack>
                            <Text c={"#98989A"}>Reading Time</Text>
                            <List sx={{borderRadius: 8}} bg={"#1A1A1A"} p={rem(16)}>
                                <List.Item>Introduction</List.Item>
                                <List.Item>Content</List.Item>
                            </List>
                        </Stack>
                    </Stack>

                </Grid.Col>
            </Grid>
        </Stack>
    );
};

export default NewsDetailPage;
