import {
  Navbar as MantineNavbar,
  NavbarProps as MantineNavbarProps,
  ScrollArea,
} from "@mantine/core";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";


import { LinksGroup } from "./LinksGroup";
import { useStyles } from "./styles";
import {cn} from "../../../util/misc";
import {getNavigation} from "../../../routers/helper/navigation";

interface NavbarProps extends Omit<MantineNavbarProps, "children"> {
  opened: boolean;
  toggleNavbar: VoidFunction;
  close: VoidFunction;
}

export const Navbar = ({ className, opened, close, ...otherProps }: NavbarProps) => {
  const { classes } = useStyles();

  const location = useLocation();
  useEffect(() => {
    close();
  }, [close, location]);

  return (
    <MantineNavbar
      width={{ sm: 230 }}
      hidden={!opened}
      className={cn(classes.navbar, className)}
      {...otherProps}
    >
      {/*<Divider color={"gray.2"} className="-mx-4 -mt-px sm:hidden" />*/}

      <MantineNavbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>
          {getNavigation().map((item: any) => (
            <LinksGroup {...item} key={item.label} />
          ))}
        </div>
      </MantineNavbar.Section>
      {/*<MantineNavbar.Section className={classes.footer}>*/}
      {/*  <UserButton*/}
      {/*    image={user?.data.avatar}*/}
      {/*    name={`${user?.data.username}`}*/}
      {/*    email={user?.data.email}*/}
      {/*  />*/}
      {/*</MantineNavbar.Section>*/}
    </MantineNavbar>
  );
};
