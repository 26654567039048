import {ResponseListAdmin, Contacts} from "./types";
import { get, post } from '../index';

interface QueryParams {
    page?: number;
    limit?: number;
    search?: string;
    author?: string;
}

const contactsList = async (queryParams?: QueryParams): Promise<ResponseListAdmin<Contacts>> => {
    let url = '/contacts/admin/contact-list';

    const params: Record<string, string> = {};
    if (queryParams) {
        Object.entries(queryParams).forEach(([key, value]) => {
            if (value !== "") {
                params[key] = value;
            }
        });
    }

    if (Object.keys(params).length > 0) {
        const queryString = new URLSearchParams(params).toString();
        url += `?${queryString}`;
    }
    return await get(url);
};
const contactsCreate = async (body: any): Promise<ResponseListAdmin<Contacts>> => {
    let url = `/contacts/create`;
    return await post(url, body);
};


export const ContactsService = {
    contactsList,
    contactsCreate
};
