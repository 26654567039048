import { Loader, MantineProvider } from "@mantine/core";
import * as React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import { Notifications } from "@mantine/notifications";

import "dayjs/locale/vi";
import { theme } from "../commons/theme";
import {ConfigProvider} from "antd";
import {ToastContainer} from "react-toastify";

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <div className="flex h-screen w-screen items-center justify-center">
          <Loader variant="dots" />
        </div>
      }
    >
        <ConfigProvider theme={{ token: { colorPrimary: '#00b96b' },
            components: {
                Table: {
                    headerColor: 'white',
                    headerBg: '#272D3D',
                    cellFontSize: 14,
                    stickyScrollBarBg: '#272D3D',

                },
            },}}>
            <div className="App">
                <Router>
                    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
                        {children}
                    </MantineProvider>

                </Router>
                <Notifications position="top-right" containerWidth={350} />

                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </div>
        </ConfigProvider>
    </React.Suspense>
  );
};
