import { NotificationProps, notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";


export const notifySuccess = (params: NotificationProps) => {
  notifications.show({
    color: "teal",
    icon: <IconCheck />,
    title: "Successfully",
    ...params,
  });
};

export const notifyError = (params: NotificationProps) => {
  notifications.show({
    color: "red",
    icon: <IconX />,
    title: "Failure",
    ...params,
  });
};
