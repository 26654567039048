import {Group, Pagination, rem} from "@mantine/core";
import React, { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
import CustomAdminTable from "../../../commons/DataListPaginationAdmin/CustomTableAdmin";
import CreateAdminModal from "../modalAdmin/CreateAdminModal";
import {Contacts} from "../../../api/admin/types";
import {ContactsService} from "../../../api/admin/contactsAPI";

export const ContactsList = () => {
    // const { t } = useTranslation();
    const [userData, setUserData] = useState<Contacts[]>([]);
    const [queryParams, setQueryParams] = useState(
        { page: 1,limit : 10, author: "", search : "" }
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(0);
    const [isModalCreateOpen, setModalCreateOpen] = useState(false);
    const [updated, setUpdated] = useState<boolean>(false);


    const columnUser = [
        { title: "Content", dataIndex: "content", width: "30%" },
        { title: "Email", dataIndex: "email", width: "20%" },
        { title: "Phone", dataIndex: "phone", width: "15%" },
        { title: "Subject", dataIndex: "subject", width: "10%" },
        { title: "Date", dataIndex: "createdAt", width: "15%" },
        { title: "Name", dataIndex: "name", width: "10%" },
    ];

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        setQueryParams(prevParams => ({
            ...prevParams,
            page: page
        }));
    };

    useEffect(() => {
        ContactsService.contactsList(queryParams).then(data => {
            const contactList = data.data.items.map((contact, index) => {
                contact.createdAt = new Date(contact.createdAt).toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true
                });
                setUpdated(false);
                return contact;
            });
            setUserData(contactList);
            setCurrentPage(data.data.metaData.currentPage);
            setPageSize(data.data.metaData.totalPages);
        });
    }, [queryParams, updated, isModalCreateOpen]);

    return (
        <>
            <CustomAdminTable columns={columnUser as any} data={userData as any}></CustomAdminTable>
            {pageSize > 1 && (
                <Pagination.Root
                    total={pageSize}
                    defaultValue={1}
                    value={currentPage}
                    onChange={(page) => handlePageChange(page)}
                >
                    <Group spacing={pageSize} position="center" mt={rem(16)}>
                        {pageSize > 1 && <Pagination.First />}
                        {pageSize > 1 && <Pagination.Previous />}
                        <Pagination.Items />
                        {pageSize > 1 && <Pagination.Next />}
                        {pageSize > 1 && <Pagination.Last />}
                    </Group>
                </Pagination.Root>
            )}

            <CreateAdminModal open={isModalCreateOpen} onClose={() => setModalCreateOpen(false)}/>
        </>
    );
};
