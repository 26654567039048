import {Navigate, Outlet, useLocation} from "react-router-dom";

import {lazyImport} from "./helper/lazyImport";
import {RoutePaths} from "./route-paths";
import {HomeRoutes} from "./module/homeRouter";
// import {AdminUserRoutes} from "./module/adminRouter";
import {Suspense} from "react";
import {Flex, Loader} from "@mantine/core";
import {UserLayout} from "../components/UserLayout/UserLayout";
import {AuthRouter} from "./module/authRouter";

const {NotFoundPage} = lazyImport(() => import("../commons/NotFoundPage"), "NotFoundPage");

interface PublicRoutesOptions {
    maintaining?: boolean;
}

const ULayout = () => {
    const location = useLocation().pathname;

    if (location === "/") {
        return <Navigate to={"/user"} state={{ from: location }} replace />;
    }

    return (
        <UserLayout>
            <Suspense
                fallback={
                    <Flex align={"center"} justify={"center"} w={"100%"} h={"100%"}>
                        <Loader variant="dots"/>
                    </Flex>
                }
            >
                <Outlet/>
            </Suspense>
        </UserLayout>
    );
};
export const publicRoutes = ({maintaining}: PublicRoutesOptions) => [
    /* Start: App Routes */
    {
        path: "/",
        element: <ULayout/>,
        children: [
            /* Start: App Routes */
            {path: "user/*", element: <HomeRoutes/>},
            /* End: App Routes */
            {index: true, element: <Navigate to={RoutePaths.NOT_FOUND} replace/>},
            {path: "*", element: <Navigate to={RoutePaths.NOT_FOUND} replace/>},
        ],
    },
    {
      path: "/auth/*",
      element: <AuthRouter />,
    },
    ...(maintaining
        ? []
        : [
            {
                path: "/auth/*",
                // element: <AuthRoutes />,
            },
        ]),

    /* End: App Routes */

    /* Start: Development Routes */
    /* End: Development Routes */

    ...(maintaining
        ? [
            {path: RoutePaths.MAINTENANCE, element: <NotFoundPage/>},
            {path: "*", element: <Navigate to={RoutePaths.MAINTENANCE} replace/>},
        ]
        : [
            {path: RoutePaths.NOT_FOUND, element: <NotFoundPage/>},
            {path: "*", element: <Navigate to={RoutePaths.NOT_FOUND} replace/>},
        ]),
];
