import {post} from '../index';
import {ApiResponse, LoginResponseType} from '../../types';

export async function login(email: string, password: string) {
    const response = await post<ApiResponse<LoginResponseType>>('/admin-auth/login', {email, password});
    return response.data;
}


export async function UserLogin(email: string, password: string) {
    const response = await post<ApiResponse<LoginResponseType>>('/auth/login', {email, password});
    return response.data;
}

export async function AuthVerify(token: string) {
    const response = await post<ApiResponse<LoginResponseType>>('/auth/verify', {token});
    return response.data;
}

export async function AuthResendVerify(email: string) {
    const response = await post<ApiResponse<LoginResponseType>>('/auth/resend-verify', {email});
    return response.data;
}

export async function SendEmailForgotPass(email: string) {
    const response = await post<ApiResponse<LoginResponseType>>('/auth/forgot-password', {email});
    return response.data;
}

export async function ResetPassword(token: string, newPassword: string) {
    const response = await post<ApiResponse<LoginResponseType>>(
        '/auth/reset-password', {token, newPassword});
    return response.data;
}

export async function CheckVerifyForgotPass(token: string) {
    const response = await post<ApiResponse<LoginResponseType>>('/auth/check-verify-forgot-password', {token});
    return response.data;
}