import { useTranslation } from "react-i18next";
import {ContentLayout} from "../../../components/ContentLayout";
import {News} from "../routes/News";
import React from "react";

export const NewsListPage = () => {
  const { t } = useTranslation();
  return (
    <ContentLayout pageTitle={t("News Management")} isAdmin={true}>
        <News></News>
    </ContentLayout>
  );
};
