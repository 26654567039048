import {
    Footer as MantineFooter,
    FooterProps as MantinefooterProps,
    createStyles,
    rem,
    Text, Flex, Center, Grid, Button, Stack,
} from "@mantine/core";

import {cn} from "../../util/misc";
import {IconArrowUpRight, IconBrandLinkedin, IconBrandTwitterFilled} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
    footer: {
        padding: `${rem(12)} ${rem(16)}`,
        fontFamily: "Inter",
        [theme.fn.largerThan("sm")]: {
            backgroundColor: "black",
            padding: `${rem(80)} ${rem(100)} ${rem(0)} ${rem(100)}`,
            maxHeight: "fit-content",
            height: "auto"
        },
        [theme.fn.smallerThan("sm")]: {
            backgroundColor: "black",
            padding: `${rem(12)} ${rem(16)}`,
            maxHeight: "fit-content",
            height: "auto"
        },
        maxHeight: "fit-content",
        height: "auto"
    },
    logo: {
        width: rem(80),
        height: rem(30),

        [theme.fn.largerThan("sm")]: {
            width: "100%",
            height: "auto",
        },
    },
    main: {
        padding: `${rem(12)} ${rem(16)}`,
        border: "none",
        [theme.fn.largerThan("sm")]: {
            backgroundColor: "black",
        },
        width: "100%"
    }
}));

interface footerProps extends Partial<MantinefooterProps> {
    opened: boolean;
    toggleNavbar?: VoidFunction;
    toggleAppShell?: VoidFunction;
    isHideAppShell?: boolean;
    title: string;
}

export const Footer = ({opened, toggleNavbar, className, ...otherProps}: footerProps) => {
    // const {t} = useTranslation();
    const {classes} = useStyles();
    // const handleLogout = useCallback(() => {
    //
    // }, []);
    return (
        <MantineFooter
            height={{base: 52, sm: 64}}
            className={cn(classes.footer, className)}
            {...otherProps}
        >
            <Stack className={cn(classes.main)}>
                <Grid sx={{borderBottom: '1px solid #262626'}} columns={10} pb={rem(80)} className="w-100"
                      gutterXs={rem(8)}>
                    <Grid.Col sm={2} span={5}>
                        <Text fz={rem(16)} mb={rem(12)} c="white">Home</Text>
                        <Stack fz={rem(14)} c="#666666">
                            <Text>Services</Text>
                            <Text>Solutions</Text>
                            <Text>Contacts</Text>
                            <Text>News</Text>
                        </Stack>

                    </Grid.Col>
                    <Grid.Col sm={2} span={5}>
                        <Text fz={rem(16)} mb={rem(12)} c="white">Service</Text>
                        <Stack fz={rem(14)} c="#666666">
                            <Text>Free Version</Text>
                            <Text>Monthly Service</Text>
                            <Text>Yearly Service</Text>
                            <Text>Web development</Text>
                            <Text>AI solutions</Text>
                        </Stack>

                    </Grid.Col>
                    <Grid.Col sm={2} span={5}>
                        <Text fz={rem(16)} mb={rem(12)} c="white">Solutions</Text>
                        <Stack fz={rem(14)} c="#666666">
                            <Text>Speech to text</Text>
                            <Text>Translation</Text>
                            <Text>AI Assistant</Text>
                        </Stack>

                    </Grid.Col>
                    <Grid.Col sm={2} span={5}>
                        <Text fz={rem(16)} mb={rem(12)} c="white">Contacts</Text>
                        <Stack fz={rem(14)} c="#666666">
                            <Stack>
                                <Text>Phone Number</Text>
                                <Text c={"white"}>(+84) 87 911 0195</Text>
                            </Stack>
                            <Stack>
                                <Text>Address</Text>
                                <Text c={"white"}>
                                    FPT University, Hanoi
                                </Text>
                            </Stack>
                        </Stack>

                    </Grid.Col>
                    <Grid.Col sm={2} span={10}>
                        <Text fz={rem(16)} mb={rem(12)} c="white">Social Media</Text>
                        <Stack fz={rem(14)} c="#666666">
                            <Button variant="default" c={"#FFD11A"} sx={{width: "fit-content"}}
                                onClick={() => window.open('https://www.facebook.com/profile.php?id=61559946963354')}
                            >
                                <Text mr={rem(8)} c={"#98989A"} fw={500}>Facebook</Text>
                                <IconArrowUpRight size={rem(16)}/>
                            </Button>
                            {/*<Button variant="default" c={"#FFD11A"} sx={{width: "fit-content"}}>*/}
                            {/*    <Text mr={rem(8)} c={"#98989A"} fw={500}>Instagram</Text>*/}
                            {/*    <IconArrowUpRight size={rem(16)}/>*/}
                            {/*</Button>*/}
                            {/*<Button variant="default" c={"#FFD11A"} sx={{width: "fit-content"}}>*/}
                            {/*    <Text mr={rem(8)} c={"#98989A"} fw={500}>Youtube</Text>*/}
                            {/*    <IconArrowUpRight size={rem(16)}/>*/}
                            {/*</Button>*/}
                            {/*<Button variant="default" c={"#FFD11A"} sx={{width: "fit-content"}}>*/}
                            {/*    <Text mr={rem(8)} c={"#98989A"} fw={500}>Tiktok</Text>*/}
                            {/*    <IconArrowUpRight size={rem(16)}/>*/}
                            {/*</Button>*/}

                        </Stack>

                    </Grid.Col>

                </Grid>
                <Grid py={rem(16)} columns={9} gutterXs={rem(8)} c="#666666">
                    <Grid.Col sm={3} span={9} order={2} orderSm={1}>
                        <Flex align={"center"}>
                            <Text>Terms & Conditions</Text>
                            <Text c={"#262626"} mx={rem(8)}>|</Text>
                            <Text>Privacy Policy</Text>
                        </Flex>
                    </Grid.Col>
                    <Grid.Col sm={3} span={9} order={1} orderSm={2}>
                        <Center>
                            <IconBrandTwitterFilled/>
                            <Grid ml={rem(8)}>
                                <IconBrandLinkedin/>
                            </Grid>
                        </Center>
                    </Grid.Col>
                    <Grid.Col sm={3} span={9} order={3} orderSm={3}>
                        <Flex justify={"end"}>
                            © 2024 Sindeso Tech. All rights reserved.
                        </Flex>
                    </Grid.Col>

                </Grid>
            </Stack>
        </MantineFooter>
    );
};
