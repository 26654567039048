import {ResponseListAdmin, User} from "./types";
import { del, get, post } from '../index';

interface QueryParams {
  page?:number;
  limit?:number;
  status?: string;
  type?: string;
  search?: string;
}

const userList = async (queryParams?: QueryParams): Promise<ResponseListAdmin<User>> => {
  let url = '/users/admin/user-list';

  const params: Record<string, string> = {};
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value !== "") {
        params[key] = value;
      }
    });
  }

  if (Object.keys(params).length > 0) {
    const queryString = new URLSearchParams(params).toString();
    url += `?${queryString}`;
  }
  return await get(url);
};
const userUpdateStatus = async (id: number | undefined, body: any): Promise<ResponseListAdmin<User>> => {
  let url = `/users/admin/active-deactive/${id}`;
  return await post(url, body);
};
const userDelete = async (id: number | undefined): Promise<ResponseListAdmin<User>> => {
  let url = `/users/admin/user-del/${id}`;
  return await del(url);
};

const userCreate = async (body: any): Promise<ResponseListAdmin<User>> => {
  let url = `/users/admin/create-admin`;
  return await post(url, body);
};

const userUserAccount = async (body: any): Promise<ResponseListAdmin<User>> => {
  let url = `/auth/register`;
  return await post(url, body);
};

const registerSubcription = async (id: string): Promise<ResponseListAdmin<User>> => {
  let url = `/subscriptions/register/${id}`;
  return await post(url, {subscriptionId : id});
};


export const UserService = {
  userList,
  userUpdateStatus,
  userDelete,
  userCreate,
  userUserAccount,
  registerSubcription
};
