import {
  Box,
  Collapse,
  Group,
  ThemeIcon,
  ThemeIconProps,
  UnstyledButton,
  createStyles,
  rem,
} from "@mantine/core";
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {cn} from "../../../util/misc";


const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    color: theme.colorScheme === "dark" ? theme.colors.titleAdmin[0] : theme.black,
    fontSize: theme.fontSizes.md,

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  activeControl: {
    color: `${
      theme.colorScheme === "dark" ? theme.colors.titleAdmin[1] : theme.colors.yellow[6]
    } !important`,
    fontWeight: 500,
  },

  link: {
    fontWeight: 500,
    display: "block",
    textDecoration: "none",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    paddingLeft: rem(31),
    marginLeft: rem(30),
    fontSize: theme.fontSizes.sm,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.gray[7],
    borderLeft: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    "&:hover": {
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  activeLink: {
    color: `${
      theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.colors.yellow[6]
    } !important`,
    fontWeight: 600,
  },

  chevron: {
    transition: "transform 200ms ease",
  },
}));

export interface LinksGroupProps {
  icon: any;
  iconColor?: ThemeIconProps["color"];
  label: string;
  parentPath?: string;
  link?: string;
  initiallyOpened?: boolean;
  links?: { label: string; link: string }[];
}

export const LinksGroup = ({
  icon: Icon,
  iconColor,
  label,
  parentPath = "",
  link,
  initiallyOpened,
  links,
}: LinksGroupProps) => {
  const { classes, theme } = useStyles();
  const [opened, setOpened] = useState(initiallyOpened || false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const hasLinks = useMemo(() => Array.isArray(links), [links]);

  const checkActive = useCallback(() => {
    if (link && location.pathname === link) {
      return true;
    }

    if (parentPath) {
      if (hasLinks) {
        let hasActiveLink = false;

        links!.forEach((link) => {
          if (location.pathname === link.link) {
            hasActiveLink = true;
          }
        });

        if (hasActiveLink) {
          if (!opened) {
            setOpened(true);
          }

          return false;
        }
      }

      const sameParentPath = location.pathname.includes(parentPath);

      if (sameParentPath && !opened) {
        setOpened(true);
      }

      return sameParentPath;
    }

    return false;
  }, [hasLinks, link, links, location, parentPath, opened]);

  const isActiveControl = useMemo(() => {
    return checkActive();
  }, [checkActive]);

  const ChevronIcon = theme.dir === "ltr" ? IconChevronRight : IconChevronLeft;

  return (
    <>
      <UnstyledButton
        onClick={() => {
          if (link) {
            navigate(link);
          } else {
            setOpened((currentState) => !currentState);
          }
        }}
        className={cn(classes.control, {
          [classes.activeControl]: isActiveControl,
        })}
      >
        <Group
          position="apart"
          spacing={0}
          sx={{ "&:hover": { backgroundColor: "#272D3D", borderRadius: 3 }, height: 36 }}
        >
          <Box sx={{ display: "flex", alignItems: "center", overflow: "hidden" }}>
            <ThemeIcon variant="light" color={iconColor} size={30} bg={"none"}>
              <Icon
                size="1.1rem"
                color={isActiveControl ? theme.colors.titleAdmin[1] : theme.colors.titleAdmin[0]}
              />
            </ThemeIcon>
            <Box ml="xs">{t(label)}</Box>
          </Box>
          {hasLinks && (
            <ChevronIcon
              className={classes.chevron}
              size="1rem"
              stroke={1.5}
              style={{
                transform: opened ? `rotate(${theme.dir === "rtl" ? -90 : 90}deg)` : "none",
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks && (
        <Collapse in={opened}>
          {links!.map((link) => (
            <NavLink
              key={link.label}
              to={link.link}
              className={({ isActive }) =>
                cn(classes.link, {
                  [classes.activeLink]: isActive,
                })
              }
            >
              {t(link.label)}
            </NavLink>
          ))}
        </Collapse>
      )}
    </>
  );
};
