import React from "react";
import { Center, rem, Stack, Table } from "@mantine/core";

interface Column {
  title: string;
  dataIndex: string;
  width?: number;
  render?: (record: DataItem) => JSX.Element;
}

interface DataItem {
  key: React.Key;

  [key: string]: any;
}

interface CommonTableProps {
  columns: Column[];
  data: DataItem[];
  renderRowButton?: (record: DataItem) => JSX.Element;
}

const CustomAdminTable: React.FC<CommonTableProps> = ({ columns, data, renderRowButton }) => {
  const rows = data.map((record: DataItem) => (
    <tr key={record.key}>
      {columns.map(col => (
        <td key={col.dataIndex}
            style={{
              width: col.width,
              color: record[col.dataIndex] === "Active" ? "green" : record[col.dataIndex] === "Inactive" ? "red" : "white"
            }}>
          {col.render ? col.render(record) : record[col.dataIndex]}
        </td>
      ))}
      {renderRowButton && (
        <td style={{ textAlign: "end" }}>{renderRowButton(record)}</td>
      )}
    </tr>
  ));

  return (
    <>
      <Table verticalSpacing="md"
             highlightOnHover
             horizontalSpacing="lg"
             bg="#272D3D"
             color="white"
             style={{ tableLayout: "fixed" }}
      >
        <thead style={{ color: "white" }}>
        <tr>
          {columns.map(col => (
            <th key={col.dataIndex} style={{ width: col.width, color: "white" }}>{col.title}</th>
          ))}
          {renderRowButton && (
            <th></th>
          )}
        </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      {rows.length === 0 ? (
        <Center w="100%" h={rem(500)}>
          <Stack c="white">
            No Results
          </Stack>

        </Center>
      ) : ''}
    </>
  );
};

export default CustomAdminTable;
