import React, {useState, useEffect} from 'react';
import "./admin-styles.scss";
import {Bounce, toast} from "react-toastify";
import {LoginResponseType} from "../../types";
import {login} from "../../api/auth/login";
import { useNavigate } from 'react-router';
import userStore from "../../store/userAdminStore";

const LoginAdmin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const setUser = userStore(state => state.setUser);

    useEffect(() => {
        const auth = localStorage.getItem('accessToken');
        if(auth) {
            navigate('/admin/user/user-list')
        }
    }, [navigate]);

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        // Validate username and password (you can add more validation logic here)
        if (username.trim() === '' || password.trim() === '') {
            toast.error('Username and password are required.', {transition: Bounce, theme: 'dark'});
            return;
        }
        try {
            const response: LoginResponseType = await login(username, password);
            if (response.access_token) {
                localStorage.setItem('accessToken', response.access_token);
                localStorage.setItem('email', username);
            }

            setUser({email: username, plan: 'ADMIN'})
            navigate('/admin/user/user-list')
            toast.success('Login Successful', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                transition: Bounce,
            });
            setUsername('');
            setPassword('');
        } catch (e) {
            toast.error(`Error: ${e}`, {transition: Bounce, theme: 'dark'});
        }

    };

    return (
        <div className="admin-background d-flex align-items-center justify-content-center">
            <div className="login-form" style={{borderRadius: 9}}>
                <div className="title-admin fw-bold text-start" style={{fontSize:'24px', color:'#7B8FF0', marginBottom:33}}>Login</div>
                <form onSubmit={handleSubmit} className="form-login">
                    <div style={{marginBottom: 24}}>
                        <div>Email or Username</div>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div style={{marginBottom: 24}}>
                        <div>Password</div>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    {/*{error && <div style={{ color: 'red' }}>{error}</div>}*/}
                    <button type="submit" className="btn-login-admin">Login</button>
                </form>
            </div>
        </div>
    );
};

export default LoginAdmin;
