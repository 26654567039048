const options = [
  { value: 'zh', key: 'zh-TW', label: '中文', voice: 'zh-TW-HsiaoChenNeural' },
  { value: 'en', key: 'en-US', label: 'English', voice: 'en-US-JennyMultilingualNeural' },
  { value: 'ja', key: 'ja-JP', label: '日本語', voice: 'ja-JP-NaokiNeural' },
  { value: 'ko', key: 'ko-KR', label: '한국어', voice: 'ko-KR-JiMinNeural' },
  { value: 'fr', key: 'fr-FR', label: 'Française', voice: 'fr-FR-DeniseNeural' },
  { value: 'es', key: 'es-CO', label: 'Español', voice: 'es-CO-SalomeNeural' },
  { value: 'de', key: 'de-DE', label: 'Deutsch', voice: 'de-DE-KatjaNeural' },
  { value: 'it', key: 'it-IT', label: 'Italiano', voice: 'it-IT-ElsaNeural' },
  { value: 'pt', key: 'pt-PT', label: 'Português', voice: 'pt-PT-RaquelNeural' },
  { value: 'pt-BR', key: 'pt-BR', label: 'Português Brasileiro', voice: 'pt-BR-FranciscaNeural' },
  { value: 'tr', key: 'tr-TR', label: 'Türk dili', voice: 'tr-TR-EmelNeural' },
  { value: 'vi', key: 'vi-VN', label: 'Tiếng Việt', voice: 'vi-VN-HoaiMyNeural' },
  { value: 'th', key: 'th-TH', label: 'ภาษาไทย', voice: 'th-TH-PremwadeeNeural' },
  { value: 'id', key: 'id-ID', label: 'Bahasa Indonesia', voice: 'id-ID-GadisNeural' },
  // Add more options as needed
];

export default options;
