import {Button, Flex, Group, Pagination, rem, Select, TextInput} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {IconChevronDown, IconSearch} from "@tabler/icons-react";
import CustomAdminTable from "../../../commons/DataListPaginationAdmin/CustomTableAdmin";
import ConfirmationModalAdmin from "../modalAdmin/ConfirmationModalAdmin";
import {News} from "../../../api/admin/types";
import {notifyError, notifySuccess} from "../../../util/notification";
import {findError} from "../../../util/misc";
import {NewsService} from "../../../api/admin/newAPI";
import CreateNewsModal from "../modalAdmin/CreateNews";

export const NewsList = () => {
    const {t} = useTranslation();
    const [newsData, setNewsData] = useState<News[]>([]);
    const [queryParams, setQueryParams] = useState(
        {page: 1, limit: 10, author: "", search: ""}
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalCreateOpen, setModalCreateOpen] = useState(false);
    const [newsSelection, setNewsSelection] = useState<News>();
    const [updated, setUpdated] = useState<boolean>(false);
    const [modalAction, setModalAction] = useState({
        title: "Delete News",
        description: "This news will be deleted. \n" +
            "Please confirm if you want to continue.",
        cancelText: "Cancel",
        confirmText: "Confirm",
        type: "Delete"
    });

    const columnUser = [
        {title: "Content", dataIndex: "content", width: "15%"},
        {title: "Title", dataIndex: "title", width: "20%"},
        {title: "Like", dataIndex: "likes", width: "15%"},
        {title: "View", dataIndex: "views", width: "10%"},
        {title: "Date", dataIndex: "createdAt", width: "10%"},
        {title: "Author", dataIndex: "userId", width: "10%"},
        {title: "Status", dataIndex: "status", width: "10%"}
    ];

    const renderRowButton = (record: any) => (
        <>
            <Button variant={"subtle"} className="mr-3" style={{color: "#7B8FF0"}}
                    onClick={() => handleEdit(record)}>{record.status === "Active" ? "Deactivate" : "Activate"}
            </Button>
            {record.role !== "Admin" && (
                <Button variant={"subtle"} style={{color: "#7B8FF0"}}
                        onClick={() => handleDelete(record)}>Delete</Button>
            )}
        </>
    );

    useEffect(() => {
        setQueryParams(prevParams => ({
            ...prevParams,
            search: searchText
        }));
    }, [searchText]);


    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        setQueryParams(prevParams => ({
            ...prevParams,
            page: page
        }));
    };

    const handleStatusChange = (status: string) => {
        setQueryParams(prevParams => ({
            ...prevParams,
            status: status === "All" ? "" : status.toLowerCase()
        }));
    };

    // const handleRoleChange = (role: string) => {
    //     setQueryParams(prevParams => ({
    //         ...prevParams,
    //         type: role === "All" ? "" : role === 'User' ? '1' : '2'
    //     }));
    // };


    useEffect(() => {
        NewsService.NewsList(queryParams).then(data => {
            const newsList = data.data.items.map((n, index) => {
                n.key = index;
                n.createdAt = new Date(n.createdAt).toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                    hour: "numeric",
                    minute: "2-digit",
                    hour12: true
                });
                n.content = n.content.substring(0, 100) + "...";
                setUpdated(false);
                return n;
            });
            setNewsData(newsList);
            setCurrentPage(data.data.metaData.currentPage);
            setPageSize(data.data.metaData.totalPages);
        });
    }, [queryParams, updated, isModalCreateOpen]);

    const handleEdit = (record: any) => {
        setModalAction({
            title: record.status === "Active" ? "Deactivate News" : "Activate News",
            description: "This news will be deactivated. \n" +
                "Please confirm if you want to continue.",
            cancelText: "Cancel",
            confirmText: "Confirm",
            type: "Update"
        });
        setIsModalOpen(true);
        setNewsSelection(record);
    };

    const handleDelete = (record: any) => {
        setModalAction({
            title: "Delete news",
            description: "This news will be deleted. \n" +
                "Please confirm if you want to continue.",
            cancelText: "Cancel",
            confirmText: "Confirm",
            type: "Delete"
        });
        setIsModalOpen(true);
        setNewsSelection(record);
    };

    const handleConfirm = () => {
        switch (modalAction.type) {
            case "Delete":
                NewsService.newDelete(newsSelection?.id).then(res => {
                    setUpdated(true);
                    notifySuccess({message: t("News has been deleted.")});
                }).catch(err => {
                    setUpdated(false);
                    notifyError({message: t(findError(err))});
                });
                break;
            default:
                NewsService.newsUpdate(newsSelection?.id, newsSelection?.status === 'inactive' ? 'active' : 'inactive').then(res => {
                    setUpdated(true);
                    notifySuccess({message: t(newsSelection?.status === "Active" ? "User has been deactivated." : "User has been activated.")});
                }).catch(err => {
                    setUpdated(false);
                    notifyError({message: t(findError(err))});
                });
                break;
        }

        setIsModalOpen(false);
    };

    return (
        <>
            <Flex
                justify="space-between"
                align="self-end"
                my={16}
            >
                <Flex>
                    <Select
                        label="Status"
                        placeholder="Pick one"
                        rightSection={<IconChevronDown size="1rem"/>}
                        rightSectionWidth={30}
                        styles={{
                            rightSection: {pointerEvents: "none"},
                            label: {color: "white"},
                            dropdown: {color: "white"},
                            input: {color: "white", backgroundColor: "#1D222E", borderColor: "#3E4760", height: rem(42)}
                        }}
                        maw={rem(110)}
                        height={rem(42)}
                        mr={8}
                        c="white"
                        defaultValue="All"
                        data={["All", "Active", "Inactive"]}
                        onChange={handleStatusChange}
                    />
                    <TextInput
                        icon={<IconSearch size="0.8rem"/>}
                        label="Search"
                        placeholder="Search by email or username"
                        height={42}
                        miw={251}
                        c="white"
                        styles={{
                            label: {color: "white"},
                            input: {
                                color: "white",
                                backgroundColor: "transparent",
                                borderColor: "#3E4760",
                                height: rem(42)
                            },
                            description: {color: "#4F4F4F"}
                        }}
                        value={searchText}
                        onChange={(event) => setSearchText(event.currentTarget.value)}
                    />
                </Flex>
                <Button bg="#7B8FF0" fz={14} fw={700} onClick={() => setModalCreateOpen(true)}>
                    {t("Create News")}
                </Button>
            </Flex>
            <CustomAdminTable columns={columnUser as any} data={newsData as any}
                              renderRowButton={renderRowButton}></CustomAdminTable>
            {pageSize > 1 && (
                <Pagination.Root
                    total={pageSize}
                    defaultValue={1}
                    value={currentPage}
                    onChange={(page) => handlePageChange(page)}
                >
                    <Group spacing={pageSize} position="center" mt={rem(16)}>
                        {pageSize > 1 && <Pagination.First/>}
                        {pageSize > 1 && <Pagination.Previous/>}
                        <Pagination.Items/>
                        {pageSize > 1 && <Pagination.Next/>}
                        {pageSize > 1 && <Pagination.Last/>}
                    </Group>
                </Pagination.Root>
            )}

            <ConfirmationModalAdmin
                confirmText={modalAction.confirmText}
                cancelText={modalAction.cancelText}
                description={modalAction.description}
                title={modalAction.title}
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)} onConfirm={handleConfirm}
            />
            <CreateNewsModal open={isModalCreateOpen} onClose={() => setModalCreateOpen(false)}/>
        </>
    );
};
