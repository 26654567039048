import React, {useEffect} from 'react';
import {Box, Button, Center, Container, Flex, rem, Text, TextInput, Title, Divider, Image} from "@mantine/core";
import {useForm} from "@mantine/form";
import './loginStyles.scss';
import Background from '../../../images/loginUserbackground.png'
import {notifyError} from "../../../util/notification";
import {findError} from "../../../util/misc";
import {useTranslation} from "react-i18next";
import {UserLogin} from "../../../api/auth/login";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "../../../routers/route-paths";
import logo from "../../../images/sindesoLogo.png";
import {handleNavigate} from "../../../utils";

const LoginUser = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const form = useForm({
        initialValues: {
            email: "",
            password: ""
        },

        validate: {
            email: (value, values) => {
                if (!value) return 'Email address is required.';
                return null;
            },
            password: (value, values) => {
                if (!value) return 'Password is required.';
                return null;
            },
        },
        validateInputOnChange: true
    });
    const handleSubmit = async () => {
        UserLogin(form.values.email, form.values.password).then(res => {
            form.reset();
            localStorage.setItem('accessToken', res.access_token);
            localStorage.setItem('email', form.values.email);
            navigate(RoutePaths.USER_HOME)
            // notifySuccess({ message: t("New admin has been created.") });
        }).catch(err => {
            notifyError({message: t(findError(err))});
        });
    };
    useEffect(() => {
        const auth = localStorage.getItem('accessToken');

        if (auth) {
            navigate(RoutePaths.USER_HOME)
        }
    }, [navigate]);

    return (
        <>
            <Flex bg="#06010E"
                  style={{backgroundImage: `url(${Background})`, overflowX: "hidden", backgroundRepeat: "no-repeat"}}
                  className="background-login" c="white"
                  mih="100vh" sx={{position: "relative", justifyContent: "center"}} justify={{sm: 'right'}}>
                <Container sx={{position: "absolute", left: "24px", bottom: "24px", display: "none"}}
                           display={{sm: "block"}} fz={rem(51)} fw={700}>
                    <Title>JOIN US TO</Title>
                    <Text className="text-world" sx={{}} bg="linear-gradient(to bottom, #501794, #AE69FF)">UNLOCK THE
                        WORLD</Text>
                </Container>
                <Flex onClick={() => handleNavigate(navigate, RoutePaths.USER_HOME)} align={"center"}
                      sx={{position: "absolute", left: "24px", top: "24px", cursor: "pointer"}} fz={rem(51)} fw={700}>
                    <Image src={logo} width={40} height={40} mr={rem(12)}/>
                    <Text c={"white"} className="d-none d-sm-block">Sindeso Tech</Text>
                </Flex>


                <Center bg={{sm: "linear-gradient(to right, #06010E 0%, #06010E 100%)", xs: `url(${Background})`}}
                        w={{xs: '100%', sm: '35%'}}>
                    <Container>
                        <Title fz={rem(78)} mb={8}>Login</Title>
                        <Text>Please enter your an account!</Text>
                        <Box component="form" mx="auto" onSubmit={form.onSubmit(handleSubmit)}>
                            <TextInput
                                styles={{
                                    label: {color: "white"},
                                    input: {
                                        color: "white",
                                        backgroundColor: "#261046",
                                        border: "none",
                                        height: rem(42)
                                    },
                                    description: {color: "#4F4F4F"}
                                }}
                                label="Email Address"
                                placeholder="Enter email address"
                                withAsterisk
                                mt="md"
                                {...form.getInputProps("email")}
                            ></TextInput>
                            <TextInput
                                styles={{
                                    label: {color: "white"},
                                    input: {
                                        color: "white",
                                        backgroundColor: "#261046",
                                        border: "none",
                                        height: rem(42)
                                    },
                                    description: {color: "white"}
                                }}
                                label="Enter Password"
                                placeholder="Enter password"
                                description="Use 8 or more characters with at least 1 uppercase, numbers & symbols"
                                inputWrapperOrder={['label', 'input', 'description', 'error']}
                                withAsterisk
                                c="white"
                                type="password"
                                mt="md"
                                {...form.getInputProps("password")}
                            ></TextInput>
                            <Button type="submit" mt={rem(30)} mih={rem(50)} sx={{border: "none", borderRadius: '10px'}}
                                    fz={rem(23)} w="100%"
                                    bg={"linear-gradient(to right, #501794 0%, #3E70A1 100%)"}>Login</Button>
                        </Box>
                        <Divider my={rem(30)}></Divider>
                        <Flex justify={"space-between"}>
                            <Flex fz={rem(10)} fw={600} c={"B6B6B6"}>REGISTER AN ACCOUNT?
                                <Text sx={{cursor: "pointer"}} ml={rem(4)} c="#9D5CE9"
                                      onClick={() => navigate(RoutePaths.AUTH_REGISTER)
                                      }> SIGN IN</Text>
                            </Flex>
                            <Flex fz={rem(10)} fw={600} c={"B6B6B6"} justify={"end"}>
                                <Text sx={{cursor: "pointer"}} ml={rem(4)} c="#9D5CE9"
                                      onClick={() => navigate(RoutePaths.FORGOT_PASSWORD)
                                      }> Forgot password?</Text>
                            </Flex>
                        </Flex>

                    </Container>
                </Center>
            </Flex>
        </>
    );
};

export default LoginUser;
