import React, {useEffect} from 'react';
// import {
//     createStyles,
//     rem
// } from "@mantine/core";

// const useStyles = createStyles((theme) => ({
//     container: {
//         padding: `${rem(100)} ${rem(100)} ${rem(100)} ${rem(100)}`,
//         [theme.fn.smallerThan("sm")]: {
//             padding: `${rem(50)} ${rem(16)} ${rem(50)} ${rem(16)}`,
//         },
//     },
//     titleTop: {
//         position: "absolute",
//         top: '8rem',
//         right: rem(30),
//         width: "40%",
//         [theme.fn.smallerThan("sm")]: {
//             position: "unset",
//             width: '100%',
//             fontSize: rem(14)
//         },
//     },
//     textUnlock: {
//         fontSize: rem(80),
//         [theme.fn.smallerThan("sm")]: {
//             fontSize: rem(26),
//         },
//     },
//     paymentCard: {
//         marginTop: rem(80),
//         [theme.fn.smallerThan("sm")]: {
//             marginTop: rem(20),
//         },
//     }
// }));

const DetailPayment = () => {
    // const {classes} = useStyles();

    useEffect(() => {
    }, []);

    return (
        <>123</>
    );
};

export default DetailPayment;
