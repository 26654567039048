import React, {useState} from 'react';
import "./admin-styles.scss";
import {UserOutlined, MailOutlined, AuditOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router';
import userStore from "../../store/userAdminStore";
import {UserList} from "./ListPage";
import {Title} from "@mantine/core";
import {ReportsList} from "./routes/ReportsList";
import {News} from "./routes/News";


const AdminDashboard = () => {
    const [selectedNavItem, setSelectedNavItem] = useState(0);
    const navigate = useNavigate();
    const user = userStore(state => state.user);
    const navbarItems = [
        {
            icon: <UserOutlined />,
            text: 'Users'
        },
        {
            icon: <AuditOutlined />,
            text: 'News'
        },
        {
            icon: <MailOutlined />,
            text: 'Contacts'
        }
    ];
    return (
        <div className="user-list-container">
            <div className="header-admin d-flex justify-content-between align-items-center">
                <div className="fw-bold" style={{color: '#7B8FF0', marginLeft: 50, fontSize: 20}}>Sindeso Admin</div>
                <div className="d-flex" style={{color: 'white', marginRight: 80, fontSize: 14}}>
                    <div style={{marginRight: 8}}>{user.email}</div>
                    |
                    <div style={{marginLeft: 8, color: '#7F869A', cursor: 'pointer'}} onClick={() => {
                        localStorage.removeItem('accessToken');
                        navigate('/admin/login')

                    }}>Logout</div>
                </div>
            </div>
            <div className="d-flex">

                <div className="navbar-container" style={{padding: '16px', width: '15%'}}>
                    {navbarItems.map((item, index) => (
                        <div className={`navbar-item cursor-pointer d-flex align-items-center ${selectedNavItem === index ? 'selected-nav' : ''}`}
                             key={index}
                             onClick={() => setSelectedNavItem(index)}
                        >
                            {item.icon}
                            <div style={{marginLeft: 16}}>{item.text}</div>
                        </div>
                    ))}
                </div>
                <div className="admin-user-container" style={{padding: '16px 32px', width: '85%'}}>
                    <Title c="white">{navbarItems[selectedNavItem].text} Management</Title>
                    {selectedNavItem === 0 && (
                        <UserList></UserList>
                    )}
                    {selectedNavItem === 1 && (
                        <News></News>
                    )}
                    {selectedNavItem === 2 && (
                        <ReportsList></ReportsList>
                    )}

                </div>
            </div>
        </div>
    );
};

export default AdminDashboard;
