import React, {useState, useEffect} from 'react';
import {
    Button,
    Center,
    Container,
    createStyles,
    Flex,
    Grid, Image,
    rem,
    Stack,
    Text,
    Title,
    Group,
    Pagination, Spoiler
} from "@mantine/core";
import {cn} from "../../../util/misc";
import {
    IconHeart, IconEye
} from "@tabler/icons-react";
import {NewsService} from "../../../api/admin/newAPI";
import {News} from "../../../api/admin/types";
import {RoutePaths} from "../../../routers/route-paths";
import {useNavigate} from "react-router-dom";

const useStyles = createStyles((theme) => ({
    container: {
        padding: `${rem(100)} ${rem(100)} ${rem(100)} ${rem(100)}`,
        [theme.fn.smallerThan("sm")]: {
            padding: `${rem(50)} ${rem(16)} ${rem(50)} ${rem(16)}`,
        }
    },
    titleTop: {
        position: "absolute",
        top: '10rem',
        right: rem(30),
        width: "70%",
        [theme.fn.smallerThan("sm")]: {
            position: "unset",
            width: '100%',
            fontSize: rem(14)
        },
    },
    textUnlock: {
        fontSize: rem(80),
        borderBottom: '1px solid #262626',
        paddingBottom: rem(70),
        [theme.fn.smallerThan("sm")]: {
            fontSize: rem(26),
        },
    },
    paymentCard: {
        marginTop: rem(50),
        paddingBottom: rem(50),
        borderBottom: '1px solid #262626',
        [theme.fn.smallerThan("sm")]: {
            marginTop: rem(20),
        },
    }
}));

const NewsPage = () => {
    const navigate = useNavigate();
    const {classes} = useStyles();
    const [pageSize, setPageSize] = useState(7);
    const [currentPage, setCurrentPage] = useState(1);
    const [newsDataList, setNewsDataList] = useState<News[]>([]);
    const [updated, setUpdated] = useState<boolean>(false);
    const [queryParams, setQueryParams] = useState(
        {page: 1, limit: 7}
    );

    useEffect(() => {
        NewsService.NewsUserList(queryParams).then(data => {
            const userNewsList = data.data.items.map((news, index) => {
                news.key = index;
                news.createdAt = new Date(news.createdAt).toLocaleString("en-US", {
                    month: "short",
                    day: "2-digit",
                    year: "numeric",
                    hour12: true
                });
                return news;
            });
            setNewsDataList(userNewsList);
            setCurrentPage(data.data.metaData.currentPage);
            setPageSize(data.data.metaData.totalPages);
            setUpdated(false);
        }).catch(() => {
            setUpdated(true);
        });
    }, [queryParams, updated]);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        setQueryParams(prevParams => ({
            ...prevParams,
            page: page
        }));
    };

    const handleLikePost = (id: number) => {
        NewsService.likeNews(id).then(() => setUpdated(true))
    };

    const handleViewPost = (id: number) => {
        NewsService.viewNews(id)
            .then(res => navigate(`${RoutePaths.USER_NEWS}/${id}`));
    };


    return (
        <>
            {newsDataList &&
                <Grid c={"white"} bg={"black"} className={cn(classes.container, 'user-home-container w-100')}>
                    <Stack className={cn(classes.textUnlock, 'position-relative w-100')}>Today's Headlines: Stay<br/>Informed
                        <Stack className={cn(classes.titleTop)} fz={rem(18)}>Explore the latest news from around the
                            world. We
                            bring you up-to-the-minute updates on the most significant events, trends, and stories.
                            Discover the
                            world through our news coverage.</Stack>
                    </Stack>
                    <Grid w="100%" columns={12} c="black" className={cn(classes.paymentCard)}>
                        <Grid.Col sm={4} span={12}>
                            <Container p={0} bg={"white"} sx={{borderRadius: "30px"}}>
                                {/*<Image height={80} src="defaultImage.png" />*/}
                                <Image sx={{cursor: "pointer"}} src={newsDataList[0]?.banner || "/defaultImage.png"}
                                       alt="default image" className="w-100"
                                       onClick={() => handleViewPost(newsDataList[0]?.id)}/>

                            </Container>

                        </Grid.Col>
                        <Grid.Col sm={8} span={12}>
                            <Center className="h-100">
                                <Stack c="white" w="95%">
                                    <Title sx={{cursor: "pointer"}} fz={rem(32)}
                                           onClick={() => handleViewPost(newsDataList[0]?.id)}>{newsDataList[0]?.title}</Title>
                                    <Spoiler hideLabel="hide" showLabel="more" maxHeight={120} mb={rem(16)} fz={rem(18)} fw={300}
                                          c={"#98989A"}>{newsDataList[0]?.content}</Spoiler>
                                    <Flex mt={rem(20)} align={"center"} fz={18} c={"white"} fw={300} gap={rem(40)}>
                                        <Stack spacing={rem(8)}>
                                            <Text c={"#98989A"}>Category</Text>
                                            <Text>Technologies</Text>
                                        </Stack>
                                        <Stack spacing={rem(8)}>
                                            <Text c={"#98989A"}>Date</Text>
                                            <Text>{newsDataList[0]?.createdAt}</Text>
                                        </Stack>
                                        <Stack spacing={rem(8)}>
                                            <Text c={"#98989A"}>Author</Text>
                                            <Text>Sindeso Tech</Text>
                                        </Stack>
                                    </Flex>
                                    <Flex align={"center"} justify={"space-between"}>
                                        <Flex>
                                            <Button onClick={() => handleLikePost(newsDataList[0]?.id)} h={rem(43)}
                                                    radius={30} variant={"default"} mr={rem(8)}>
                                                <IconHeart/>
                                                {/*<IconHeartFilled/>*/}
                                                &nbsp; {newsDataList[0]?.likes}
                                            </Button>
                                            <Button h={rem(43)} radius={30} variant={"default"}>
                                                <IconEye/>&nbsp;{newsDataList[0]?.views}</Button>

                                        </Flex>
                                        <Button variant={"default"}
                                                onClick={() => handleViewPost(newsDataList[0]?.id)}>Read More
                                        </Button>
                                    </Flex>
                                </Stack>
                            </Center>
                        </Grid.Col>
                    </Grid>
                    <Grid gutter={"lg"} w="100%" columns={12} c="black" mt={rem(70)}>
                        {newsDataList.slice(1).map((newsItem, index) => (
                            <Grid.Col sm={4} span={12} mb={rem(8)} key={index}>
                                <Stack c="white">
                                    <img onClick={() => handleViewPost(newsItem?.id)} src={newsItem?.banner || "/defaultImage.png"} alt="defaultimage" style={{maxHeight: rem(220)}}/>
                                    <Title onClick={() => handleViewPost(newsItem?.id)} fz={rem(20)}>{newsItem?.title}</Title>
                                    <Spoiler hideLabel="hide" showLabel="more" maxHeight={120} mb={rem(18)} fz={rem(18)}
                                             fw={300} c={"#98989A"}>
                                        {newsItem?.content}
                                    </Spoiler>
                                    <Flex align={"center"} justify={"space-between"}>
                                        <Flex>
                                            <Button onClick={() => handleLikePost(newsItem?.id)} h={rem(43)} radius={30} variant={"default"} mr={rem(8)}>
                                                <IconHeart/>
                                                {/*<IconHeartFilled/>*/}
                                                &nbsp;  {newsItem?.likes}
                                            </Button>
                                            <Button h={rem(43)} radius={30} variant={"default"}>
                                                <IconEye/>&nbsp;{newsItem?.views}</Button>

                                        </Flex>
                                        <Button onClick={() => handleViewPost(newsItem?.id)} variant={"default"}>Read More</Button>
                                    </Flex>
                                </Stack>
                            </Grid.Col>
                        ))}

                    </Grid>
                    <Center my={rem(30)} w={"100%"}>
                        {pageSize > 1 && (
                            <Pagination.Root
                                total={pageSize}
                                defaultValue={1}
                                value={currentPage}
                                onChange={(page) => handlePageChange(page)}
                            >
                                <Group spacing={pageSize} position="center" mt={rem(16)}>
                                    {pageSize > 1 && <Pagination.First/>}
                                    {pageSize > 1 && <Pagination.Previous/>}
                                    <Pagination.Items/>
                                    {pageSize > 1 && <Pagination.Next/>}
                                    {pageSize > 1 && <Pagination.Last/>}
                                </Group>
                            </Pagination.Root>
                        )}
                    </Center>
                </Grid>}
        </>
    );
};

export default NewsPage;
