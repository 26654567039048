import {
  Header as MantineHeader,
  HeaderProps as MantineHeaderProps,
  Title,
  createStyles,
  rem,
  Text, Flex,
} from "@mantine/core";
import { Link } from "react-router-dom";

import { useCallback } from "react";
// import { useTranslation } from "react-i18next";
import {cn} from "../../util/misc";
import {RoutePaths} from "../../routers/route-paths";
import logo from "../../images/sindesoLogo.png";

const useStyles = createStyles((theme) => ({
  header: {
    padding: `${rem(12)} ${rem(16)}`,
    border: "none",
    [theme.fn.largerThan("sm")]: {
      backgroundColor: "#14141C",
      padding: `${rem(0)} ${rem(120)} ${rem(0)} ${rem(70)}`,
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    width: rem(50),
    height: rem(50),

    [theme.fn.largerThan("sm")]: {
      width: '100%',
      height: '100%',
    },
  },
}));

interface HeaderProps extends Partial<MantineHeaderProps> {
  opened: boolean;
  toggleNavbar: VoidFunction;
  toggleAppShell: VoidFunction;
  isHideAppShell: boolean;
  title: string;
}

export const Header = ({ opened, toggleNavbar, className, ...otherProps }: HeaderProps) => {
  // const { t } = useTranslation();
  const { classes } = useStyles();
  const handleLogout = useCallback(() => {

  }, []);
  return (
      <MantineHeader
          height={{ base: 52, sm: 64 }}
          className={cn(classes.header, className)}
          {...otherProps}
      >
        {/*<div className="flex items-center justify-between sm:hidden">*/}
        {/*  <Logo width={rem(80)} height={rem(30)} />*/}
        {/*  <Burger opened={opened} onClick={toggleNavbar} size="sm" />*/}
        {/*</div>*/}

        <Flex align="center" justify="space-between" h="100%">
          <Link to={RoutePaths.ROOT} className={classes.logo} >
            <img alt={"logo"} src={logo} height={50} width={50}/>
          </Link>
          <Title
              color="white"
              align="center"
              fz={rem(18)}
              fw={700}
              className="flex hidden sm:block"
          >
            <div className="flex items-center justify-items-end	">

              <Flex>
                <Text size="sm" sx={{ marginRight: 10, marginLeft: 10 }}>
                  {localStorage.getItem('email')}
                </Text>
                <Text size="sm">|</Text>
                <Text size="sm" color="dimmed" sx={{ marginLeft: 10, cursor: "pointer" }} onClick={handleLogout}>
                  Logout
                </Text>
              </Flex>

              {/* {icon || <IconChevronRight size="0.9rem" stroke={1.5} />} */}
            </div>
          </Title>
          {/*<Burger opened={opened} onClick={toggleNavbar} size="sm" className="sm:hidden" />*/}
        </Flex>
      </MantineHeader>
  );
};
