import { useTranslation } from "react-i18next";

import {ContentLayout} from "../../../components/ContentLayout";
import {UserList} from "../ListPage";
import React from "react";

export const UserListPage = () => {
  const { t } = useTranslation();
  return (
    <ContentLayout pageTitle={t("User Management")} isAdmin={true}>
        <UserList></UserList>
    </ContentLayout>
  );
};
