import React, {useEffect} from 'react';
import {
    Box, Button,
    Center,
    createStyles, Flex, Grid,
    rem, Stack, Textarea, TextInput, Title, Text
} from "@mantine/core";
import Background from '../../../images/contactBackground.png'
import {IconMailFilled, IconPhoneFilled} from "@tabler/icons-react";
import {isNotEmpty, useForm} from "@mantine/form";
import {ContactsService} from "../../../api/admin/contactsAPI";
import {notifyError, notifySuccess} from "../../../util/notification";
import {findError} from "../../../util/misc";
import {useTranslation} from "react-i18next";


const useStyles = createStyles((theme) => ({
    container: {
        padding: `${rem(100)} ${rem(100)} ${rem(100)} ${rem(100)}`,
        [theme.fn.smallerThan("sm")]: {
            padding: `${rem(50)} ${rem(16)} ${rem(50)} ${rem(16)}`,
        }
    },

    textInput: {
        label: {
            color: "#242331",
            fontSize: rem(16),
            fontWeight: 500,
            marginBottom: rem(12),
        },
        input: {
            "::placeholder": {
                color: "#808080!important",
            },
            color: "#242331",
            height: rem(56),
            backgroundColor: "white",
            borderRadius: 20,
            borderColor: "#DCDCDC",
            padding: rem(12)
        },
        wrapper: {
            backgroundColor: "white",
            borderRadius: 20,
        }
    },
    form: {
        width: "100%",
        borderRadius: 30,
        padding: `${rem(90)} ${rem(60)}`,
        [theme.fn.smallerThan("sm")]: {
            padding: `${rem(40)} ${rem(20)}`,
        }
    }

}));

const ContactPage = () => {
    const {t} = useTranslation();
    const {classes} = useStyles();
    const form = useForm({
        initialValues: {
            username: "",
            email: "",
            phone: "",
            subject: "",
            message: ""
        },

        validate: {
            username: (value, values) => {
                if (!value) return 'Username is required.';
                return null;
            },
            email: (value, values) => {
                if (!value) return 'Email address is required.';
                if (!(/^\S+@\S+$/.test(value))) return 'Invalid email format.';
                return null;
            },
            phone: (value, values) => {
                if (!value) return 'Phone is required.';
                return null;
            },
            subject: isNotEmpty("Please input your subject"),
            message: isNotEmpty("Please input your message")
        },
        validateInputOnChange: true
    });

    useEffect(() => {
    }, []);

    const handleSubmit = async () => {
        const contacts = {
            name: form.values.username,
            email: form.values.email,
            phone: form.values.phone,
            subject: form.values.subject,
            content: form.values.message
        };
        ContactsService.contactsCreate(contacts).then(res => {
            form.reset();
            notifySuccess({message: t("Success")});
        }).catch(err => {
            notifyError({message: t(findError(err))});
        });
    };

    return (
        <>
            <Stack spacing={0} bg={"black"} className={"w-100"}>
                <Stack mt={rem(50)} h={rem(1300)} spacing={0} style={{
                    backgroundImage: `url(${Background})`,
                    backgroundSize: '100% 50%',
                    overflowX: "hidden",
                    overflowY: "hidden",
                    backgroundRepeat: "no-repeat"
                }} className={"w-100 position-relative"}>
                    <Center mt={rem(180)} c={"white"}>
                        <Stack>
                            <Title className={"text-center"} fz={rem(60)}>Get in Touch</Title>
                            <Flex align={"center"} justify={"space-between"} c={"white"}>
                                <Flex align={"center"} mr={rem(30)}>
                                    <IconMailFilled size={rem(16)}
                                                    style={{minWidth: rem(20)}}/>
                                    &nbsp;sindeso.tech@gmail.com
                                </Flex>
                                <Flex align={"center"} className={"w-50"}>
                                    <IconPhoneFilled size={rem(16)} style={{minWidth: rem(20)}}/>&nbsp;(084) 879 110
                                    195
                                </Flex>
                            </Flex>
                        </Stack>
                    </Center>
                    <Center className={"w-100"} mt={rem(50)}>
                        <Box onSubmit={form.onSubmit(handleSubmit)} component="form" className="w-75"
                             sx={{boxShadow: "0 60px 120px -60px #1B16FF"}}>
                            <Grid columns={12} bg={"white"} className={classes.form}>
                                <Grid.Col sm={6} span={12}>
                                    <TextInput
                                        className={classes.textInput}
                                        label="Full name"
                                        placeholder="Your name"
                                        withAsterisk
                                        {...form.getInputProps("username")}
                                    />
                                </Grid.Col>
                                <Grid.Col sm={6} span={12}>
                                    <TextInput
                                        className={classes.textInput}
                                        label="Your Email"
                                        placeholder="example@yourmail.com"
                                        withAsterisk
                                        {...form.getInputProps("email")}
                                    />
                                </Grid.Col>
                                <Grid.Col sm={6} span={12}>
                                    <TextInput
                                        className={classes.textInput}
                                        label="Phone"
                                        placeholder="(+84) 87 123 4567"
                                        withAsterisk
                                        {...form.getInputProps("phone")}
                                    />
                                </Grid.Col>
                                <Grid.Col sm={6} span={12}>
                                    <TextInput
                                        className={classes.textInput}
                                        label="Subject"
                                        placeholder="How can we help you?"
                                        withAsterisk
                                        {...form.getInputProps("subject")}
                                    />
                                </Grid.Col>
                                <Grid.Col sm={12} span={12}>
                                    <Textarea
                                        styles={{
                                            input: {
                                                backgroundColor: "white",
                                                borderColor: "#DCDCDC",
                                                padding: rem(20),
                                                color: "black"
                                            }
                                        }}
                                        radius={20}
                                        bg={"white"}
                                        className={classes.textInput}
                                        label="Message"
                                        placeholder="Hello there, I would like to talk about how to..."
                                        autosize
                                        minRows={12}
                                        maxRows={20}
                                        {...form.getInputProps("message")}
                                    />
                                </Grid.Col>
                                <Button type="submit" radius={30} h={rem(45)} mt={rem(40)} variant={"default"}>
                                    Send Message
                                </Button>

                            </Grid>

                        </Box>

                    </Center>
                </Stack>
                <Center className={"w-100"} mb={rem(200)}>
                    <Stack c={"white"}>
                        <Title className={"text-center"} fz={rem(50)}>
                            We’re Global and Growing
                        </Title>
                        <Text>
                            Building an enterprise level site doesn't need nightmare or cost your thousands. Felix is
                            purpose built
                        </Text>
                        <Grid gutter={rem(30)} columns={12} className="w-100">
                            <Grid.Col sm={6} span={12}>
                                <Flex sx={{borderRadius: 20}} w={"100%"} align={"center"} justify={"center"}
                                      bg={"white"}>
                                    <Stack p={rem(20)} py={rem(60)}>
                                        <Center w={"100%"}>
                                            <Center fw={700}
                                                    w={rem(80)}
                                                    h={rem(80)}
                                                    c={"#DD7FA1"}
                                                    p={rem(12)}
                                                    bg={"#FFDCE9"}
                                                    sx={{borderRadius: "50%"}}>
                                                HL
                                            </Center>
                                        </Center>
                                        <Center>
                                            <Title c={"#2F2F2F"} className={"text-center"} fz={rem(30)}>
                                                FPT University
                                            </Title>
                                        </Center>
                                        <Center>
                                            <Text c={"#808080"} fz={rem(20)}>
                                                1000 Hola
                                            </Text>
                                        </Center>
                                    </Stack>
                                </Flex>
                            </Grid.Col>
                            <Grid.Col sm={6} span={12}>
                                <Flex sx={{borderRadius: 20}} w={"100%"} align={"center"} justify={"center"}
                                      bg={"white"}>
                                    <Stack p={rem(20)} py={rem(60)}>
                                        <Center w={"100%"}>
                                            <Center fw={700}
                                                    w={rem(80)}
                                                    h={rem(80)}
                                                    c={"#3694D8"}
                                                    p={rem(12)}
                                                    bg={"#B1DEFF"}
                                                    sx={{borderRadius: "50%"}}>
                                                HN
                                            </Center>
                                        </Center>
                                        <Center>
                                            <Title c={"#2F2F2F"} className={"text-center"} fz={rem(30)}>
                                                Sindeso Tech
                                            </Title>
                                        </Center>
                                        <Center>
                                            <Text c={"#808080"} fz={rem(20)}>
                                                100 Cau Giay
                                            </Text>
                                        </Center>
                                    </Stack>
                                </Flex>
                            </Grid.Col>
                        </Grid>
                    </Stack>
                </Center>
            </Stack>

        </>
    );
};

export default ContactPage;
