import React, {useEffect, useState} from 'react';
import {
    ActionIcon,
    Button,
    Center, CopyButton,
    createStyles, Flex, Group, Image, Loader,
    rem, Stack, Stepper, Text, Tooltip
} from "@mantine/core";

import {useNavigate, useParams} from "react-router-dom";
import {cn, findError} from "../../../util/misc";
import Payment from "../../../images/Payment.jpg";
import {useUserStore} from "../../../store/useUserStore";
import {PAYMENT_TYPE} from "../../../types";
import {IconArrowUpRight, IconCheck, IconCircleDashedCheck, IconCopy, IconExclamationCircle} from "@tabler/icons-react";
import {handleNavigate} from "../../../utils";
import {RoutePaths} from "../../../routers/route-paths";
import {UserService} from "../../../api/admin/userAPI";
import {notifyError, notifySuccess} from "../../../util/notification";
import {useTranslation} from "react-i18next";

const useStyles = createStyles((theme) => ({
    mainPayment: {
        padding: rem(20),
    },
    qrCodeContainer: {
        borderRadius: 20,
        [theme.fn.smallerThan("sm")]: {},
    },
}));

const PaymentPage = () => {
    const {classes} = useStyles();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {type} = useParams();
    const {user, fetchUser} = useUserStore();
    const [active, setActive] = useState(0);
    const [adminActive, setAdminActive] = useState(true);
    const [time, setTime] = useState(60);
    const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));

    const verifySubscription = () => {
        nextStep();
        if (type) {
            UserService.registerSubcription(type).then(res => {
                notifySuccess({ message: t("Request has been sent.") });
            }).catch(err => {
                notifyError({ message: t(findError(err)) });
            });
        }
    }

    useEffect(() => {
            fetchUser().then();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [navigate]);

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        if (active === 1) {
            timer = setInterval(() => {
                setTime((prevTime) => {
                    if (prevTime > 0) {
                        return prevTime - 1;
                    } else {
                        setAdminActive(false);
                        setActive(2);
                        clearInterval(timer);
                        return 0;
                    }
                });
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [active]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };


    const paymentMethod = () => {
        return type === PAYMENT_TYPE.PAYMENT_HOUR ? "Hour" : type === PAYMENT_TYPE.PAYMENT_MONTH ? "Monthly" : "Yearly"
    }

    useEffect(() => {
    }, []);


    return (
        <>
            <Center className={cn(classes.mainPayment, "vh-100")}>
                <Stack>
                    <Center mb={rem(20)}>
                        {active === 0 &&
                            <Center>
                                <Stack className={cn(classes.qrCodeContainer, "")}>
                                    <Center>
                                        <Image src={Payment} width={250} height={350}></Image>
                                    </Center>
                                    <Flex>
                                        <Flex mr={8} align={"center"}>
                                            <Text fw={700}>Message:</Text>
                                            <Flex ml={30} fz={12}>
                                                {user?.email} bk {paymentMethod()}
                                            </Flex>
                                        </Flex>
                                        <CopyButton value={`${user?.email} bk ${paymentMethod()}`} timeout={2000}>
                                            {({copied, copy}) => (
                                                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                                                    <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                                                        {copied ? <IconCheck size="1rem"/> : <IconCopy size="1rem"/>}
                                                    </ActionIcon>
                                                </Tooltip>
                                            )}
                                        </CopyButton>

                                    </Flex>
                                </Stack>
                            </Center>
                        }
                        {active === 1 &&
                            <Center>
                                <Stack className={cn(classes.qrCodeContainer, "")}>
                                    <Center h={300}>
                                        <Loader size={48}></Loader>
                                    </Center>
                                </Stack>
                            </Center>
                        }
                        {active === 2 &&
                            <Center>
                                {adminActive &&
                                    <Stack>
                                        <Center>
                                            <IconCircleDashedCheck size={80} color="green"/>
                                        </Center>
                                        <Text fz={24}>Thanks for payment. Welcome to SindesoTech</Text>
                                    </Stack>
                                }
                                {!adminActive &&
                                    <Stack>
                                        <Center> <IconExclamationCircle size={80} color="orange"/></Center>
                                        <Text fz={24}>The payment system is temporarily under maintenance so it may take
                                            longer.
                                            Please contact admin for the fastest update</Text>
                                        <Flex align={"center"} justify={"space-between"}>
                                            <Button variant="default" c={"#FFD11A"} sx={{width: "fit-content"}}
                                                    onClick={() => handleNavigate(navigate, RoutePaths.USER_CONTACT)}
                                            >
                                                <Text mr={rem(8)} c={"#98989A"} fw={500}>Contacts</Text>
                                                <IconArrowUpRight size={rem(16)}/>
                                            </Button>
                                            <Button variant="default" c={"#FFD11A"} sx={{width: "fit-content"}}
                                                    onClick={() => window.open('http://m.me/332566886602781')}
                                            >
                                                <Text mr={rem(8)} c={"#98989A"} fw={500}>Chat with Admin</Text>
                                                <IconArrowUpRight size={rem(16)}/>
                                            </Button>
                                        </Flex>
                                    </Stack>
                                }
                            </Center>
                        }
                    </Center>
                    <Stepper active={active} onStepClick={setActive} breakpoint="sm" allowNextStepsSelect={false}>
                        <Stepper.Step label="First step" description="Complete Payment" disabled={true}>
                            <Center sx={{textAlign: "center"}}>If you have already made payment. Please click
                                verify</Center>
                        </Stepper.Step>
                        <Stepper.Step label="Second step" description="Processing" disabled={true}>
                            <Center sx={{textAlign: "center"}}>
                                <Text>We are verifying your payment. Please wait {formatTime(time)}</Text></Center>
                        </Stepper.Step>
                        <Stepper.Step label="Final step" description="Get full access" disabled={true}>
                            <Button variant="default" c={"#FFD11A"} sx={{width: "fit-content"}}
                                    onClick={() => handleNavigate(navigate, '')}
                            >
                                <Text mr={rem(8)} c={"#98989A"} fw={500}>Back to home</Text>
                                <IconArrowUpRight size={rem(16)}/>
                            </Button>
                        </Stepper.Step>
                        <Stepper.Completed>
                            Complete, click back button to get to previous stepd
                        </Stepper.Completed>
                    </Stepper>
                    {active === 0 &&
                        <Group position="center">
                            {/*<Button variant="default" onClick={prevStep}>Back</Button>*/}
                            <Button onClick={verifySubscription}>Verify</Button>
                        </Group>
                    }


                </Stack>
            </Center>
        </>
    );
};

export default PaymentPage;
