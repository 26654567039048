import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import axios from "axios";

export const translateWithAzure = async (
  keyAzure,
  region,
  fromLang,
  toLang,
  text
) => {
  try {
    const response = await axios.post(
      `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=${fromLang}&to=${toLang}`,
      [{ text }],
      {
        headers: {
          "Content-Type": "application/json",
          "Ocp-Apim-Subscription-Key": keyAzure,
          "Ocp-Apim-Subscription-Region": region,
        },
      }
    );

    return response.data[0].translations[0].text;
  } catch (error) {
    console.error("Error translating:", error.message);
    throw error;
  }
};

export const synthesizeSpeech = async (
  keyAzure,
  region,
  selectedSpeech,
  text
) => {
  const speechLang = selectedSpeech.voice;
  const speechConfig = sdk.SpeechConfig.fromSubscription(keyAzure, region);
  speechConfig.speechSynthesisVoiceName = speechLang;

  const synthesizer = new sdk.SpeechSynthesizer(speechConfig);

  return new Promise((resolve, reject) => {
    synthesizer.speakTextAsync(
      text,
      (result) => {
        if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
          const audioBlob = result.audioData;
          resolve(audioBlob);
        } else {
          reject(new Error("Speech synthesis canceled"));
        }
        synthesizer.close();
      },
      (error) => {
        reject(error);
        synthesizer.close();
      }
    );
  });
};

export function generateUUID() {
  let cryptoObj = window.crypto || window.msCrypto; // for IE 11
  if (cryptoObj) {
    // Use a typed array to store raw binary data
    let buffer = new Uint16Array(8);

    // Generate 16 random values (each 8 bits)
    cryptoObj.getRandomValues(buffer);

    // Set the version (4) and variant (2 bits from the most significant bits of the 7th byte)
    buffer[3] &= 0x0fff; // clear version bits
    buffer[3] |= 0x4000; // set version 4
    buffer[4] &= 0x3fff; // clear variant bits
    buffer[4] |= 0x8000; // set to IETF variant

    // Convert the binary data to a string representation
    let uuid = "";
    for (let i = 0; i < 8; i++) {
      uuid += (buffer[i] + 0x10000).toString(16).substring(1);
    }

    return uuid;
  } else {
    console.error("crypto API not supported");
    return null;
  }
}
