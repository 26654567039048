import { Navigate, Route, Routes } from "react-router-dom";
// import Homepage from "../../pages/Home";
import {RoutePaths} from "../route-paths";
import AdminDashboard from "../../pages/Admin/adminDashboard";
import UserHomePage from "../../pages/User/home";
import ServicesPage from "../../pages/User/ServicePage/ServicesPage";
import NewsPage from "../../pages/User/News/NewsPage";
import NewsDetailPage from "../../pages/User/News/NewsDetailPage";
import ContactPage from "../../pages/User/Contact/contact";
import DetailPayment from "../../pages/User/ServicePage/detailPayment";

export const HomeRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<UserHomePage/>} />
      <Route path="/services" element={<ServicesPage/>} />
      <Route path="/services/:id" element={<DetailPayment/>} />
      <Route path="/contact" element={<ContactPage/>} />
      <Route path="/news" element={<NewsPage/>} />
      <Route path="/news/:id" element={<NewsDetailPage/>} />
      <Route path="/admin" element={<AdminDashboard/>} />
      <Route path="*" element={<Navigate to={RoutePaths.NOT_FOUND} replace />} />
    </Routes>
  );
};
