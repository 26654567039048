import { useTranslation } from "react-i18next";
import {ContentLayout} from "../../../components/ContentLayout";
import React from "react";
import {ContactsList} from "../ListPage/ContactsList";


export const ContactListPage = () => {
  const { t } = useTranslation();
  return (
    <ContentLayout pageTitle={t("Contacts Management")} isAdmin={true}>
        <ContactsList></ContactsList>
    </ContentLayout>
  );
};
