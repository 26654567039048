/* eslint-disable no-restricted-imports */
import clsx from "clsx";

export const cn = clsx;
export function toCamel(originalObject: any): any {
  let convertedObject: any, originalKey: any, convertedKey: any, originalValue: any;

  if (originalObject instanceof Array) {
    convertedObject = [];
    for (originalKey in originalObject) {
      originalValue = originalObject[originalKey];

      if (typeof originalValue === "object") {
        originalValue = toCamel(originalValue);
      }

      convertedObject.push(originalValue);
    }
  } else {
    convertedObject = {};
    for (originalKey in originalObject) {
      // eslint-disable-next-line no-prototype-builtins
      if (originalObject.hasOwnProperty(originalKey)) {
        convertedKey = (
          originalKey.charAt(0).toLowerCase() + originalKey.slice(1) || originalKey
        ).toString();
        originalValue = originalObject[originalKey];
        if (
          originalValue instanceof Array ||
          (originalValue !== null && originalValue.constructor === Object)
        ) {
          originalValue = toCamel(originalValue);
        }
        convertedObject[convertedKey] = originalValue;
      }
    }
  }

  return convertedObject;
}

export function toCamelCase(originalObject: any): any {
  let convertedObject: any, originalKey: any, convertedKey: any, originalValue: any;

  if (originalObject instanceof Array) {
    convertedObject = [];
    for (originalKey in originalObject) {
      originalValue = originalObject[originalKey];

      if (typeof originalValue === "object") {
        originalValue = toCamel(originalValue);
      }

      convertedObject.push(originalValue);
    }
  } else {
    convertedObject = {};
    for (originalKey in originalObject) {
      // eslint-disable-next-line no-prototype-builtins
      if (originalObject.hasOwnProperty(originalKey)) {
        convertedKey = (
          originalKey.charAt(0).toUpperCase() + originalKey.slice(1) || originalKey
        ).toString();
        originalValue = originalObject[originalKey];
        if (
          originalValue instanceof Array ||
          (originalValue !== null && originalValue.constructor === Object)
        ) {
          originalValue = toCamel(originalValue);
        }
        convertedObject[convertedKey] = originalValue;
      }
    }
  }

  return convertedObject;
}

export function getValuable<T>(obj: T extends unknown ? any : any): T {
  const result = {} as T;

  Object.keys(obj).forEach((prop) => {
    const key = prop as keyof T;

    if (obj[prop] || obj[prop] === false || obj[prop] === 0 || obj[prop] === Array.isArray) {
      result[key] = obj[prop] as T[typeof key];
    }
  });

  return { ...result };
}
export function isEmpty(str: string | undefined) {
  return !str || str.length === 0;
}

export const findError = (error: { statusCode?: number; message?: string }): string[] => {
  return [`error.${error.statusCode}`, error.message || "messages.unknownError"];
};

export const checkInconsistent = (activities?: Array<{ State: number }>) =>
  [0, 1].includes(activities?.[0]?.State ?? 2);
