import React, {useEffect, useRef} from 'react';
import {
    Button,
    Center,
    Container,
    createStyles,
    Flex,
    Grid,
    List,
    rem, Select,
    Stack,
    Text,
    Title
} from "@mantine/core";
import {cn} from "../../../util/misc";
import {IconChevronDown} from "@tabler/icons-react";
import {IconTick} from "../../../components/icons/IconTick";
import {RoutePaths} from "../../../routers/route-paths";
import {useNavigate} from "react-router-dom";
import {useUserStore} from "../../../store/useUserStore";
import {PAYMENT_TYPE} from "../../../types";

const useStyles = createStyles((theme) => ({
    container: {
        padding: `${rem(100)} ${rem(100)} ${rem(100)} ${rem(100)}`,
        [theme.fn.smallerThan("sm")]: {
            padding: `${rem(50)} ${rem(16)} ${rem(50)} ${rem(16)}`,
        },
    },
    titleTop: {
        fontSize: rem(60),
        [theme.fn.smallerThan("sm")]: {
            position: "unset",
            width: '100%',
            fontSize: rem(14)
        },
    },
    textUnlock: {
        fontSize: rem(60),
        [theme.fn.smallerThan("sm")]: {
            fontSize: rem(28),
        },
    },
    paymentCard: {
        marginTop: rem(80),
        [theme.fn.smallerThan("sm")]: {
            marginTop: rem(20),
        },
    }
}));

const ServicesPage = () => {
    const {classes} = useStyles();
    const navigate = useNavigate();
    const {user} = useUserStore();
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
    }, []);

    useEffect(() => {
        const video = videoRef.current;
        if (video) {
            video.play().catch((error) => {
                console.error('Error attempting to play video:', error);
            });
        }
    }, []);

    const handleClickPayment = (type: string) => {
        if (!user) {
            navigate(RoutePaths.AUTH_LOGIN)
        } else {
            switch (type) {
                case PAYMENT_TYPE.PAYMENT_FREE:
                    navigate(RoutePaths.DASHBOARD)
                    break;
                case PAYMENT_TYPE.PAYMENT_MONTH :
                    if (user.type === PAYMENT_TYPE.PAYMENT_MONTH || user.type === PAYMENT_TYPE.PAYMENT_YEAR) {
                        navigate(RoutePaths.DASHBOARD)
                    } else {
                        navigate(`${RoutePaths.PAYMENT_PAGE}/${PAYMENT_TYPE.PAYMENT_MONTH}`)
                    }
                    break;
                case PAYMENT_TYPE.PAYMENT_YEAR:
                    if (user.type === PAYMENT_TYPE.PAYMENT_YEAR) {
                        navigate(RoutePaths.DASHBOARD)
                    } else {
                        navigate(`${RoutePaths.PAYMENT_PAGE}/${PAYMENT_TYPE.PAYMENT_YEAR}`)
                    }
                    break;
                default:
                    if (user.type === PAYMENT_TYPE.PAYMENT_HOUR) {
                        navigate(RoutePaths.DASHBOARD)
                    } else {
                        navigate(`${RoutePaths.PAYMENT_PAGE}/${PAYMENT_TYPE.PAYMENT_HOUR}`)
                    }
                    break;
            }
        }

    }

    return (
        <Grid c={"white"} bg={"black"} className={cn(classes.container, 'user-home-container w-100')}>
            <Stack className={cn(classes.textUnlock, 'position-relative w-100')}>Unlock the World of Speeching
                Artificial
                Intelligence
                <Stack className={cn(classes.titleTop)} fz={rem(18)}>Dive deep into the AI universe with our collection
                    of insightful. Explore the latest trends,
                    breakthroughs. Whether you're an enthusiast or a professional, our AI Voice offer a gateway to
                    knowledge and innovation.</Stack>
                <Stack w={'60%'} mx={'auto'}>
                    <video ref={videoRef} loop controls autoPlay playsInline>
                        <source src="/videos/tvc.mp4" type="video/mp4" />
                    </video>
                </Stack>
            </Stack>
            <Grid gutter={rem(30)} w="100%" columns={12} c="black" className={cn(classes.paymentCard)}>
                <Grid.Col sm={4} span={12} mt={rem(40)}>
                    <Container p={0} bg={"white"} sx={{borderRadius: "30px", boxShadow: "0 60px 120px -60px #5F81F7"}}>
                        <Title fz={{base: rem(16), xl: rem(20)}} fw={700} py={{base: rem(24), xl: rem(36)}}
                               px={rem(24)}>STANDARD</Title>
                        <Center fz={rem(40)} fw={700} mb={{base: rem(24), xl: rem(30)}} py={{base: rem(8), xl: rem(20)}}
                                sx={{borderTop: '1px solid #DCDCDC', borderBottom: '1px solid #DCDCDC'}}>Free</Center>
                        <Stack px={rem(24)} mb={rem(40)}>
                            <List
                                spacing="xs"
                                size="sm"
                                center
                                icon={
                                    <IconTick color="#9649F8"/>
                                }
                                fz={{base: rem(16), xl: rem(20)}}
                                c={"#808080"}
                            >
                                <List.Item c="black">Quick supports</List.Item>
                                <List.Item c="black">Speech to text</List.Item>
                                <List.Item c="black">Interpreter</List.Item>
                                <List.Item
                                    icon={
                                        <IconTick color="#636363"/>
                                    }
                                >
                                    Save history
                                </List.Item>
                                <List.Item icon={
                                    <IconTick color="#636363"/>
                                }>Ask and answer with AI</List.Item>
                                <List.Item icon={
                                    <IconTick color="#636363"/>
                                }>Customize behaviors</List.Item>
                                <List.Item icon={
                                    <IconTick color="#636363"/>
                                }>Intelligent Sindeso recognizing</List.Item>
                            </List>
                            <Button fz={rem(18)}
                                    radius={30}
                                    mt={{base: rem(15), xl: rem(30)}}
                                    mb={{base: rem(20), xl: rem(30)}}
                                    h={{base: rem(60), xl: rem(75)}}
                                    bg="#5956E9"
                                    onClick={() => handleClickPayment(PAYMENT_TYPE.PAYMENT_FREE)}
                            >
                                Try It
                            </Button>
                        </Stack>

                    </Container>
                </Grid.Col>
                <Grid.Col sm={4} span={12}>
                    <Container p={0} bg={"white"} sx={{borderRadius: "30px", boxShadow: "0 60px 120px -60px #DB14F1"}}>
                        <Flex align={"center"} justify={"space-between"}>
                            <Title fz={{base: rem(16), xl: rem(20)}} fw={700} py={{base: rem(24), xl: rem(36)}}
                                   px={rem(24)}>MONTHLY</Title>
                            <Button h={{base: rem(44), xl: rem(51)}} mr={rem(24)} px={{base: rem(30), xl: rem(36)}}
                                    radius={rem(20)}
                                    variant={"gradient"}>Popular</Button>
                        </Flex>
                        <Center fz={rem(40)} fw={700} mb={{base: rem(24), xl: rem(30)}} py={{base: rem(8), xl: rem(20)}}
                                sx={{borderTop: '1px solid #DCDCDC', borderBottom: '1px solid #DCDCDC'}}>
                            29.000đ <Text fz={{base: rem(16), xl: rem(20)}}>/m</Text>
                        </Center>
                        <Stack px={rem(24)} mb={rem(40)}>
                            <List
                                spacing="xs"
                                size="sm"
                                center
                                icon={
                                    <IconTick color="#9649F8"/>
                                }
                                fz={{base: rem(16), xl: rem(20)}}
                                c={"black"}
                            >
                                <List.Item c="black">Quick supports</List.Item>
                                <List.Item c="black">Speech to text</List.Item>
                                <List.Item c="black">Interpreter</List.Item>
                                <List.Item>
                                    Save history
                                </List.Item>
                                <List.Item>
                                    Customize behaviors
                                </List.Item>
                                <List.Item>Ask and answer with AI</List.Item>
                                <List.Item>Intelligent Sindeso recognizing</List.Item>
                            </List>
                            <Button fz={rem(18)}
                                    radius={30}
                                    mt={{base: rem(15), xl: rem(30)}}
                                    mb={{base: rem(20), xl: rem(30)}}
                                    h={{base: rem(60), xl: rem(75)}}
                                    bg="#5956E9"
                                    onClick={() => handleClickPayment(PAYMENT_TYPE.PAYMENT_MONTH)}
                            >
                                {user?.type === PAYMENT_TYPE.PAYMENT_MONTH
                                || user?.type === PAYMENT_TYPE.PAYMENT_YEAR ? "Try it" : "Buy It"}
                            </Button>
                        </Stack>

                    </Container>
                </Grid.Col>
                <Grid.Col sm={4} span={12} mt={rem(40)}>
                    <Container p={0} bg={"white"} sx={{borderRadius: "30px", boxShadow: "0 60px 120px -60px #D8D246"}}>
                        <Flex align={"center"} justify={"space-between"}>
                            <Title fz={{base: rem(16), xl: rem(20)}} fw={700} py={{base: rem(24), xl: rem(36)}}
                                   px={rem(24)}>YEAR</Title>
                            <Button h={{base: rem(44), xl: rem(51)}} mr={rem(24)} px={{base: rem(30), xl: rem(36)}}
                                    radius={rem(20)} variant={"default"}>Save
                                30%</Button>
                        </Flex>
                        <Center fz={rem(40)} fw={700} mb={{base: rem(24), xl: rem(30)}} py={{base: rem(8), xl: rem(20)}}
                                sx={{borderTop: '1px solid #DCDCDC', borderBottom: '1px solid #DCDCDC'}}>
                            150.000đ <Text fz={{base: rem(16), xl: rem(20)}}>/y</Text>
                        </Center>
                        <Stack px={rem(24)} mb={rem(40)}>
                            <List
                                spacing="xs"
                                size="sm"
                                center
                                icon={
                                    <IconTick color="#9649F8"/>
                                }
                                fz={{base: rem(16), xl: rem(20)}}
                                c={"black"}
                            >
                                <List.Item c="black">Quick supports</List.Item>
                                <List.Item c="black">Speech to text</List.Item>
                                <List.Item c="black">Interpreter</List.Item>
                                <List.Item>
                                    Save history
                                </List.Item>
                                <List.Item>
                                    Customize behaviors
                                </List.Item>
                                <List.Item>Ask and answer with AI</List.Item>
                                <List.Item>Intelligent Sindeso recognizing</List.Item>
                            </List>
                            <Button
                                fz={rem(18)}
                                radius={30}
                                mt={{base: rem(15), xl: rem(30)}}
                                mb={{base: rem(20), xl: rem(30)}}
                                h={{base: rem(60), xl: rem(75)}}
                                bg="#5956E9"
                                onClick={() => handleClickPayment(PAYMENT_TYPE.PAYMENT_YEAR)}
                            >
                                {user?.type === PAYMENT_TYPE.PAYMENT_YEAR ? "Try it" : "Buy It"}
                            </Button>
                        </Stack>

                    </Container>
                </Grid.Col>

            </Grid>
            <Grid w="100%" columns={12} c="black" className={cn(classes.paymentCard)}>
                <Grid.Col sm={5} span={12}>
                    <Container p={0} bg={"white"} sx={{borderRadius: "30px", boxShadow: "0 60px 120px -60px #5F81F7"}}>
                        <Title fz={rem(35)} fw={700} pt={rem(36)} px={rem(40)}>Customize</Title>
                        <Text c={"#98989A"} fz={rem(18)} fw={500} px={rem(40)}>Bring your voice to the world</Text>
                        <Flex ml={rem(40)} align={"baseline"} fz={rem(50)} fw={"bold"} mt={rem(20)} c={"black"}>39.000đ
                            <Text fw={500} fz={{base: rem(16), xl: rem(20)}}>/hour</Text>
                        </Flex>
                        <Select
                            px={rem(40)}
                            my={rem(20)}
                            placeholder="Select Plan Duration"
                            rightSection={<IconChevronDown color="#9649F8"/>}
                            rightSectionWidth={30}
                            styles={{
                                rightSection: {pointerEvents: 'none'},
                                input: {
                                    background: 'White',
                                    borderColor: '#D3D3D3',
                                    borderRadius: '40px',
                                    height: rem(70),
                                    fontWeight: 700,
                                    padding: `${rem(0)} ${rem(30)} ${rem(0)} ${rem(30)}`
                                }
                            }}
                            data={['1 hour', '2 hours', '3 hours', '4 hours']}
                        />
                        <Button
                            mx={rem(40)}
                            w={"-webkit-fill-available"}
                            fz={rem(18)}
                            radius={30}
                            mt={{base: rem(15), xl: rem(30)}}
                            mb={{base: rem(20), xl: rem(30)}}
                            h={{base: rem(60), xl: rem(75)}}
                            bg="#5956E9"
                            onClick={() => handleClickPayment(PAYMENT_TYPE.PAYMENT_HOUR)}
                        >
                            Buy It
                        </Button>
                    </Container>

                </Grid.Col>
                <Grid.Col sm={7} span={12}>
                    <Center className="h-100">
                        <Stack c="white">
                            <Title fz={rem(40)}>How to customize</Title>
                            <Text mb={rem(16)} fz={rem(18)} fw={500} c={"#98989A"}>Understand the world from every
                                perspective. Instant interaction with your voice</Text>

                            <Stack mb={rem(40)}>
                                <List
                                    spacing="xs"
                                    size="sm"
                                    center
                                    icon={
                                        <IconTick color="#9649F8"/>
                                    }
                                    fz={{base: rem(16), xl: rem(20)}}
                                    c={"white"}
                                >
                                    <List.Item>Speech to text (95%)</List.Item>
                                    <List.Item>Translation imediatetly</List.Item>
                                    <List.Item>ChatGPT 4.0</List.Item>
                                    <List.Item>Premium customer support</List.Item>
                                </List>
                            </Stack>
                        </Stack>
                    </Center>
                </Grid.Col>
            </Grid>
        </Grid>
    );
};

export default ServicesPage;
