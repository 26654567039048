import { Navigate, Route, Routes } from "react-router-dom";

import {RoutePaths} from "../route-paths";
import {UserListPage} from "../../pages/Admin/Layout/UserListPage";
import {NewsListPage} from "../../pages/Admin/Layout/NewsListPage";
import {ContactListPage} from "../../pages/Admin/Layout/ContactListPage";

export const AdminUserRoutes = () => {
  return (
    <Routes>
      <Route path="/user-list" element={<UserListPage />} />
      <Route path="/news-list" element={<NewsListPage />} />
      <Route path="/contacts-list" element={<ContactListPage />} />
      <Route path="*" element={<Navigate to={RoutePaths.NOT_FOUND} replace />} />
    </Routes>
  );
};
