/* eslint-disable no-restricted-imports */
import { AppShell, Flex } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";


import { Header } from "./Header";
import { Navbar } from "./Navbar";
import {useDisclosure} from "../../hooks/useDisclosure";

interface MainLayoutProps {
  children: React.ReactNode;
}

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { isOpen: opened, toggle: toggleNavbar, close } = useDisclosure(false);
  const [hiddenAppShell, setHiddenAppShell] = useState<boolean>(true);
  const { t } = useTranslation();

  // const { data: user } = useUser();
  // const { data: roleData } = useRoleList();
  // const { setRoles } = useRoleStore();

  // useEffect(() => {
  //   roleData && setRoles(roleData);
  // }, [roleData, setRoles]);

  return (
    <AppShell hidden={hiddenAppShell} layout="alt" padding={0}>
      <Header
        opened={opened}
        isHideAppShell={hiddenAppShell}
        toggleAppShell={() => setHiddenAppShell(!hiddenAppShell)}
        toggleNavbar={toggleNavbar}
        title={t("SindesoTech")}
      />
      <Flex sx={{ borderTop: "1px solid #3E4760" }}>
        <Navbar
          hiddenBreakpoint="sm"
          close={close}
          opened={opened}
          toggleNavbar={toggleNavbar}
          style={{ flex: "0 0 auto" }}
        />

        <div style={{ flex: "1 1 auto" }}>{children}</div>
      </Flex>
    </AppShell>
  );
};
