import React, {FC, useCallback, useEffect, useState} from 'react';
import "./dashboard.scss";
import {Button, createStyles, Flex, rem, ScrollArea, Select, Stack, Text, Title} from "@mantine/core";
import {IconTick} from "../../components/icons/IconTick";
import {IconArrowsExchange, IconBrain, IconFileExport, IconLanguage, IconLock, IconReload} from "@tabler/icons-react";
import options from "../../helpers/custom-dropdown/optionLanguage";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import AzureInputService from "../dashboard/services/azureInputService";
import AzureOutputService from "../dashboard/services/azureOutputService";
import AzureOpenAIOutputService from "./services/OpenAIOutputService";
// @ts-ignore
import SpeechRecognition, {useSpeechRecognition,} from "react-speech-recognition";
import {generateUUID} from "./services/azure-service";
import {useUserStore} from "../../store/useUserStore";
import ConfirmationModalAdmin from "../Admin/modalAdmin/ConfirmationModalAdmin";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "../../routers/route-paths";
import {exportToExcel} from "../../utils";
import {PAYMENT_TYPE} from "../../types";

interface Option {
    value: string;
    key: string;
    label?: string;
    voice?: string;
    speechKey?: string;
}

export interface Translation {
    recognizedText: string;
    resultTranslator: string | null;
    asr?: string;
    tra?: string;
    status?: number;
    choices?: Choices[];
}

interface Choices {
    message?: {
        content?: string;
    }
}


const useStyles = createStyles((theme) => ({
    buttonContainer: {
        flexWrap: "wrap",
        [theme.fn.smallerThan("md")]: {
            flexFlow: "wrap-reverse"
        }
    },

    buttonStart: {
        [theme.fn.smallerThan("md")]: {
            marginBottom: rem(8),
            flexFlow: "row-reverse",
            alignItems: "center"
        }
    },
}));

interface IProps {
    asrProps: number;
}

const SindesoAI: FC<IProps> = ({asrProps}) => {
    const navigate = useNavigate();
    const {user, fetchUser} = useUserStore();

    const {classes} = useStyles();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSpeech, setSelectedSpeech] = useState<string>('vi');
    const [selectedOutPut, setSelectedOutPut] = useState<string>('en');
    const [recognizing, setRecognizing] = useState<string>('');
    const [recognizer, setRecognizer] = useState<sdk.SpeechRecognizer | null>(null);
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [translationList, setTranslationList] = useState<Translation[]>([]);
    const optionTranslate: Option[] = options;

    // asr : 2 type- 1 is microsoft, 2 is free,
    // tra: 2 type- 1 is translate, 2 la chat with AI
    const [asr, setAsr] = useState(2);
    const [tra, setTra] = useState(2);
    const [startTra, setStartTra] = useState(0);
    const [count, setCount] = useState(0);
    const {
        transcript,
        finalTranscript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition();


    // recognizing free
    const startListening = useCallback(() => {
        if (browserSupportsSpeechRecognition) {
            if (SpeechRecognition.browserSupportsContinuousListening) {
                // @ts-ignore
                const speechLang = optionTranslate.find((e) => e.value === selectedSpeech).value;
                SpeechRecognition.startListening({language: speechLang});
            } else {
                alert("Continuous listening is not supported on this browser.");
            }
        } else {
            alert("Browser doesn't support speech recognition.");
        }
    }, [browserSupportsSpeechRecognition, optionTranslate, selectedSpeech]);

    useEffect(() => {
            fetchUser().then();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []);

    const revertText = (inputText: string) => {
        let characters = inputText !== null ? inputText.split("") : null;
        if (!characters) return characters;
        let reversedCharacters = characters.reverse();
        return reversedCharacters.join("");
    };
    const scrollBottom = () => {
        // const container = translationContainerRef.current;
        // if (container) {
        //     container.scrollTop = container.scrollHeight;
        // }
    };

    const resetList = () => {
        setTranslationList([]);
    };

    const startRecording = () => {
        switch (asr) {
            case 2:
                setIsRecording(true);
                startListening();
                break;
            default:
                if (recognizer) {
                    recognizer.startContinuousRecognitionAsync();
                    setIsRecording(true);
                }
        }
    };

    const stopRecording = () => {
        switch (asr) {
            case 2:
                setIsRecording(false);
                stopListening();
                break;
            default:
                if (recognizer) {
                    recognizer.stopContinuousRecognitionAsync();
                    setIsRecording(false);
                }
        }
    };

    const handleChangeTra = (newTra: number) => {
        setTra(newTra);
    };

    const handleChangeAsr = (newAsr: number) => {
        if (newAsr === 1) {
            if (user?.subscription?.pricingId.toString() === PAYMENT_TYPE.PAYMENT_HOUR) {
                setAsr(newAsr);
            } else {
                setIsModalOpen(true);
                return;
            }
        }
        setAsr(newAsr);
    };

    const stopListening = () => {
        SpeechRecognition.stopListening();
    };

    const exportData = () => {
        exportToExcel(translationList, "HistorySpeech");
    };

    const handleSelectSpeech = (selectedOption: string) => {
        setSelectedSpeech(selectedOption);
        setStartTra(0);
        if (asr === 3 && isRecording) {
            resetTranscript();
            startListening();
        }

        if (asr === 1) {
            if (isRecording && recognizer) {
                recognizer.stopContinuousRecognitionAsync();
                // @ts-ignore
                recognizer.dispose();
            }
        }
        setRecognizer(null);
    };
    const handleSelectOutPut = (selectedOption: string) => {
        setSelectedOutPut(selectedOption);
        setStartTra(0);
        if (asr === 1 && recognizer) {
            if (isRecording) {
                recognizer.stopContinuousRecognitionAsync();
                // @ts-ignore
                recognizer.dispose();
            }
        }
        setRecognizer(null);
    };

    const handleSwapLanguage = () => {
        setSelectedSpeech(selectedOutPut);
        setSelectedOutPut(selectedSpeech);
        setStartTra(0);
        if (isRecording && recognizer) {
            recognizer.stopContinuousRecognitionAsync();
            // @ts-ignore
            recognizer.dispose();
        }
        setRecognizer(null);
    };


    useEffect(() => {
        if (!recognizer) {
            const speechLang = optionTranslate.find(
                (e) => e.value === selectedSpeech
            );
            const outputLang = optionTranslate.find(
                (e) => e.value === selectedOutPut
            );
            const ASRI = new AzureInputService(
                speechLang,
                outputLang,
                isRecording,
                scrollBottom,
                setTranslationList,
                setRecognizer,
                setRecognizing,
                setStartTra,
                revertText
            );
            if (asr === 1) ASRI.startListening();
        }
    }, [recognizer, selectedSpeech, selectedOutPut, isRecording, asr, tra, optionTranslate]);

    useEffect(() => {
        const speechLang = optionTranslate.find((e) => e.value === selectedSpeech);
        const outputLang = optionTranslate.find((e) => e.value === selectedOutPut);
        const TRAII = new AzureOpenAIOutputService(
            speechLang,
            outputLang,
            translationList,
            setTranslationList,
            setStartTra,
            revertText
        );
        const TRAI = new AzureOutputService(
            speechLang,
            outputLang,
            translationList,
            setTranslationList,
            setStartTra,
            revertText
        );
        if (startTra) {
            switch (tra) {
                case 2:
                    TRAII.startAskAI();
                    break;
                default:
                    TRAI.startTranslating();
            }
        }
    }, [optionTranslate, selectedOutPut, selectedSpeech, startTra, tra, translationList]);

    useEffect(() => {
        setAsr(asrProps)
    }, [asrProps]);


    useEffect(() => {
        if (asr !== 1 && recognizer) {
            setIsRecording(false);
            recognizer.stopContinuousRecognitionAsync();
            // @ts-ignore
            recognizer.dispose();
        }
    }, [asr, recognizer, tra]);

    useEffect(() => {
            const speechLang = optionTranslate.find((e) => e.value === selectedSpeech);
            const outputLang = optionTranslate.find((e) => e.value === selectedOutPut);
            if (listening && isRecording && transcript !== "") {
                if (count === 0) {
                    const translation = {
                        recognizedText: transcript,
                        resultTranslator: null,
                        asr: speechLang?.key,
                        tra: outputLang?.key,
                        status: 0,
                    };
                    setTranslationList((prevTranslationList) => [
                        translation,
                        ...prevTranslationList,
                    ]);
                }
                setCount(1);
                setRecognizing(transcript);
            }

            if (listening === false && isRecording) {
                setCount(0);
                setTranslationList((prevTranslationList) => [
                    ...prevTranslationList.map((i) => {
                        if (!i.status) {
                            setStartTra(1);
                            return {
                                ...i,
                                recognizedText: recognizing,
                                revertRecognizedText: revertText(recognizing),
                                resultTranslator: null,
                                status: 1,
                                id: generateUUID(),
                            };
                        }
                        return i;
                    }),
                ]);
                resetTranscript();
                startListening();
            }

        },
        [listening, finalTranscript, transcript, optionTranslate, isRecording, selectedSpeech, selectedOutPut, count, resetTranscript, recognizing, startListening]);

    return (
        <>
            <Flex className="w-100 h-100 sindeso-ai">
                <Stack justify={"space-between"} className="left-layout d-none d-md-flex"
                       w={{base: "0%", md: "32%", xl: "32%"}}>
                    <Stack>
                        <Title fz={rem(24)} fw={500} c="black">
                            Mode
                        </Title>
                        <Flex onClick={() => handleChangeAsr(2)} mb={rem(8)} align={"start"} className="item-container">
                            <IconTick className="icon" color={asr === 2 ? "#9649F8" : "#636363"}/>
                            {/*{user?.subscription?.pricingId.toString() === PAYMENT_TYPE.PAYMENT_MONTH &&*/}
                            {/*    <IconTick className="icon" color={asr === 1 ? "#9649F8" : "#636363"}/>*/}
                            {/*}*/}
                            <Stack spacing={rem(4)}>
                                <Text fw={500} c="black" fz={rem(14)}>Normal Mode</Text>
                                <Text>Delivers highly efficient speech to text experience up to 80%.</Text>
                            </Stack>
                        </Flex>
                        <Flex onClick={() => handleChangeAsr(1)} align={"start"} className="item-container">
                            {user?.subscription?.pricingId.toString() === PAYMENT_TYPE.PAYMENT_HOUR &&
                                <IconTick className="icon" color={asr === 1 ? "#9649F8" : "#636363"}/>}
                            {user?.subscription?.pricingId.toString() !== PAYMENT_TYPE.PAYMENT_HOUR &&
                                <IconLock className="icon" color={asr === 1 ? "#9649F8" : "#636363"}/>}
                            <Stack spacing={rem(4)}>
                                <Text fw={500} c="black" fz={rem(14)}>Boot Mode</Text>
                                <Text>Using high technology helps improve voice recognition accuracy with up
                                    to 95% accuracy.
                                </Text>
                            </Stack>
                        </Flex>
                    </Stack>
                    <Flex gap={rem(20)} sx={{flexWrap: "wrap"}} align={"end"} className="position-relative">
                        <Select
                            styles={{
                                rightSection: {pointerEvents: "none"},
                                label: {color: "black"},
                                dropdown: {color: "white"},
                                input: {
                                    color: "black",
                                    backgroundColor: "#F7F7F9",
                                    borderColor: "#C5C5C5",
                                }
                            }}
                            filterDataOnExactSearchMatch={true}
                            label="Input"
                            placeholder="Pick one"
                            defaultValue="en"
                            value={selectedSpeech}
                            className="w-100"
                            data={options}
                            onChange={handleSelectSpeech}
                        />
                        <IconArrowsExchange
                            onClick={handleSwapLanguage}
                            className="position-absolute" size={rem(24)}
                            style={{
                                minWidth: 20,
                                top: "50%",
                                left: "50%",
                                transform: "translateX(-50%)",
                                cursor: "pointer"
                            }}
                        />
                        <Select
                            styles={{
                                rightSection: {pointerEvents: "none"},
                                label: {color: "black"},
                                dropdown: {color: "white"},
                                input: {
                                    color: "black",
                                    backgroundColor: "#F7F7F9",
                                    borderColor: "#C5C5C5",
                                    height: rem(29)
                                }
                            }}
                            filterDataOnExactSearchMatch={true}
                            label="Output"
                            className="w-100"
                            placeholder="Pick one"
                            defaultValue="vi"
                            value={selectedOutPut}
                            onChange={handleSelectOutPut}
                            data={options}
                        />
                    </Flex>
                </Stack>
                <Stack justify={"space-between"} className="right-layout w-100" p={rem(25)}>

                    {/*translation area list*/}
                    <ScrollArea scrollbarSize={2} scrollHideDelay={500} h={"90%"}>
                        {translationList.length > 0 &&
                            translationList.map((item, index) => (
                                <>
                                    <Stack key={`${index}-${(item as any).id}`} spacing={0} p={rem(8)} fz={rem(16)}
                                           fw={700}
                                           sx={{boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", borderRadius: 8}}
                                           w={"95%"} m={rem(10)}
                                    >
                                        <Text c="black">{item.status === 0 ? recognizing : item.recognizedText} </Text>
                                        <Text c="#9649F8"> {item.resultTranslator}</Text>
                                    </Stack>
                                </>

                            ))}

                    </ScrollArea>

                    {/*button area*/}
                    <Flex align={"end"} justify="space-between" className={classes.buttonContainer}>
                        <Flex className={classes.buttonContainer} gap={{base: 8, sm: 0}} sx={{flexWrap: "wrap"}}>
                            {tra === 1 ?
                                (
                                    <Button onClick={() => handleChangeTra(2)} variant="default" mr={rem(12)}>
                                        <IconLanguage/>
                                        <Text ml={rem(8)}>Translation</Text>
                                    </Button>
                                ) : (
                                    <Button onClick={() => handleChangeTra(1)} variant="default" mr={rem(12)}>
                                        <IconBrain/>
                                        <Text ml={rem(8)}>AI Support</Text>
                                    </Button>
                                )
                            }

                            <Button className="d-none d-lg-flex" variant="default" mr={rem(12)} onClick={exportData}>
                                <IconFileExport/>
                                <Text ml={rem(8)}>Export data</Text>
                            </Button>
                            {/*<Button variant="default" mr={rem(12)}>*/}
                            {/*    <IconSpacingHorizontal/>*/}
                            {/*    <Text ml={rem(8)}>Make longer</Text>*/}
                            {/*</Button>*/}
                            <Button variant="default" mr={rem(12)}>
                                <IconReload/>
                                <Text ml={rem(8)} onClick={resetList}>Reload</Text>
                            </Button>
                        </Flex>
                        <Flex className={classes.buttonStart} gap={rem(25)}>
                            {isRecording ? (
                                <Button w={rem(70)} h={rem(70)} onClick={stopRecording} radius="50%"
                                        variant="outline">Stop</Button>
                            ) : (
                                <Button w={rem(70)} h={rem(70)} onClick={startRecording} radius="50%"
                                        variant="outline">Start</Button>
                            )}

                            <Flex gap={rem(20)} sx={{flexWrap: "wrap"}} align={"end"}
                                  className="position-relative d-flex d-md-none">
                                <Select
                                    styles={{
                                        rightSection: {pointerEvents: "none"},
                                        label: {color: "black"},
                                        dropdown: {color: "white"},
                                        input: {
                                            color: "black",
                                            backgroundColor: "#F7F7F9",
                                            borderColor: "#C5C5C5",
                                        }
                                    }}
                                    filterDataOnExactSearchMatch={true}
                                    label="Input"
                                    placeholder="Pick one"
                                    defaultValue="en"
                                    value={selectedSpeech}
                                    className="w-100"
                                    data={options}
                                    onChange={handleSelectSpeech}
                                />
                                <IconArrowsExchange
                                    onClick={handleSwapLanguage}
                                    className="position-absolute" size={rem(24)}
                                    style={{
                                        minWidth: 20,
                                        top: "50%",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        cursor: "pointer"
                                    }}
                                />
                                <Select
                                    styles={{
                                        rightSection: {pointerEvents: "none"},
                                        label: {color: "black"},
                                        dropdown: {color: "white"},
                                        input: {
                                            color: "black",
                                            backgroundColor: "#F7F7F9",
                                            borderColor: "#C5C5C5",
                                            height: rem(29)
                                        }
                                    }}
                                    filterDataOnExactSearchMatch={true}
                                    label="Output"
                                    className="w-100"
                                    placeholder="Pick one"
                                    defaultValue="vi"
                                    value={selectedOutPut}
                                    onChange={handleSelectOutPut}
                                    data={options}
                                />
                            </Flex>

                        </Flex>
                    </Flex>
                </Stack>
            </Flex>
            <ConfirmationModalAdmin
                confirmText={"Upgrade Plan"}
                cancelText={"Cancel"}
                description={'Your account cannot access this function. Please contact admin or make payment with Customize Version to be able to use it.'}
                title={"Warning"}
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)} onConfirm={() => navigate(RoutePaths.USER_SERVICES)}
            />
        </>
    );
};

export default SindesoAI;
