import { create } from 'zustand';
import {UserProperty} from "../types";
import {UserDetail} from "../api/auth/user";

interface UserState {
    user: UserProperty | null;
    loading: boolean;
    error: string | null;
    fetchUser: () => Promise<void>;
}

export const useUserStore = create<UserState>((set) => ({
    user: null,
    loading: false,
    error: null,
    fetchUser: async () => {
        set({ loading: true, error: null });
        try {
            const userDetail = await UserDetail();
            set({ user: userDetail, loading: false });
        } catch (error) {
            set({ error: 'Failed to fetch user data', loading: false });
        }
    },
}));
