import React from "react";
import { Modal, Button, Group, Title, rem, Text, Flex } from "@mantine/core";

interface ConfirmationModalAdminProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  description: string;
  cancelText: string;
  confirmText: string;
}

function ConfirmationModalAdmin(
  {
    open,
    onClose,
    onConfirm,
    title,
    description,
    cancelText,
    confirmText
  }: ConfirmationModalAdminProps) {

  const handleConfirmClick = () => {
    onConfirm();
    onClose(); // Close the modal after confirming
  };

  return (
    <Modal
      opened={open}
      onClose={onClose}
      title={""}
      centered
    >
      <Group position="center" px={rem(16)} mb={rem(8)}>
        <Title c="white" fz={rem(18)} fw={700}>{title}</Title>
        <Text align="center" c="white" fz={rem(16)}>{description}</Text>
        <Flex align="center" justify="space-between" w="100%" gap={rem(16)}>
          <Button  bg="transparent" c={"#7B8FF0"} style={{border: '1px solid #7B8FF0'}} onClick={onClose} w="50%">
            {cancelText}
          </Button>
          <Button bg="#7B8FF0" w="50%" onClick={handleConfirmClick}>
            {confirmText}
          </Button>
        </Flex>
      </Group>
    </Modal>
  );
}

export default ConfirmationModalAdmin;
