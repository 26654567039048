import React, {useEffect, useState} from 'react';
import "./dashboard.scss";
import logo from "../../images/sindesoLogo.png";
import {Box, Burger, createStyles, Drawer, Flex, Image, Menu, rem, Stack, Text, Title} from "@mantine/core";
import {handleNavigate} from "../../utils";
import {RoutePaths} from "../../routers/route-paths";
import {useLocation, useNavigate} from "react-router-dom";
import {
    IconBrandGoogleHome,
    IconSettings,
    // IconAi,
    // IconLanguage,
    // IconLanguageKatakana,
} from "@tabler/icons-react";
import SindesoAI from "./sindesoAI";
import {cn} from "../../util/misc";

const useStyles = createStyles((theme) => ({
    mainContainer: {
        width: "100%",
        [theme.fn.smallerThan("xl")]: {
            width: "100%",
        }
    },
    header: {
        backgroundColor: "black",
        border: "none",
        [theme.fn.largerThan("lg")]: {
            backgroundColor: "black",
            padding: `${rem(0)} ${rem(100)} ${rem(0)} ${rem(100)}`,
        },
    },
    logo: {
        width: rem(80),
        height: rem(30),

        [theme.fn.largerThan("lg")]: {
            width: "100%",
            height: "auto",
        },
    },
    navigationText: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        ":hover": {
            color: "#9D5CE9"
        }
    }
}));


const Dashboard = () => {
    const navigate = useNavigate();
    const {classes} = useStyles();
    const location = useLocation().pathname;
    const [asr, setAsr] = useState(2);
    const [isOpenHeaderMobile, setIsOpenHeaderMobile] = useState(false);

    useEffect(() => {

    }, []);

    return (
        <Box bg={"white"} className="vh-100">
            <Flex h={100} py={rem(25)}
                  px={rem(30)}
                  bg={"white"}
                  align={"center"}
                  justify={"space-between"}
                  className={cn(classes.mainContainer, "d-none d-lg-flex")}>
                <Flex align={"center"} sx={{cursor: "pointer"}}
                      onClick={() => handleNavigate(navigate, RoutePaths.USER_HOME)}>
                    <Image src={logo} width={40} height={40}/>
                    <Title ml={rem(10)} c={"#001831"}>Sindeso Tech</Title>
                </Flex>
            </Flex>
            <Flex h={100} py={rem(25)} align={"center"} justify={"space-between"} className="d-lg-none px-3">
                <Burger opened={isOpenHeaderMobile} size="sm" color={"#011936"}/>
                <Drawer.Root opened={isOpenHeaderMobile} onClose={() => setIsOpenHeaderMobile(false)}>
                    <Drawer.Overlay/>
                    <Drawer.Content>
                        <Drawer.Header>
                            <Drawer.Title></Drawer.Title>
                            <Drawer.CloseButton/>
                        </Drawer.Header>
                        <Drawer.Body>
                            <Stack>
                                <Text className={cn(classes.navigationText, "")}
                                      c={"" === location ? '#9D5CE9' : 'white'}
                                      onClick={() => {
                                          handleNavigate(navigate, '');
                                      }}>Home</Text>
                                <Text className={cn(classes.navigationText, "")}
                                      c={RoutePaths.USER_CONTACT === location ? '#9D5CE9' : 'white'}
                                      onClick={() => {
                                          handleNavigate(navigate, RoutePaths.USER_CONTACT);
                                      }}>Contacts</Text>
                                <Text className={cn(classes.navigationText, "")}
                                      c={RoutePaths.USER_SERVICES === location ? '#9D5CE9' : 'white'}
                                      onClick={() => {
                                          handleNavigate(navigate, RoutePaths.USER_SERVICES);
                                      }}>Service</Text>
                                <Text className={cn(classes.navigationText, "")}
                                      c={RoutePaths.USER_SOLUTIONS === location ? '#9D5CE9' : 'white'}
                                      onClick={() => {
                                          handleNavigate(navigate, "");
                                      }}>Solutions</Text>
                                <Text className={cn(classes.navigationText, "className")}
                                      c={RoutePaths.USER_NEWS === location ? '#9D5CE9' : 'white'}
                                      onClick={() => {
                                          handleNavigate(navigate, RoutePaths.USER_NEWS);
                                      }}>News</Text>
                            </Stack>
                        </Drawer.Body>
                    </Drawer.Content>
                </Drawer.Root>
                <Text sx={{cursor: "pointer"}} onClick={() => handleNavigate(navigate, RoutePaths.USER_HOME)}>
                    <Image src={logo} width={30} height={30}/>
                </Text>
                <Menu width={200} shadow="md">
                    <Menu.Target>
                        <IconSettings color="#011936"/>
                    </Menu.Target>

                    <Menu.Dropdown bg={"white"}>
                        <>
                            <Menu.Item component="a" bg={asr === 2 ? "#5956E9" : "white"} >
                                <Flex onClick={() => setAsr(2)} mb={rem(8)} align={"start"}
                                      className="item-container">
                                    <Stack spacing={rem(4)} c={asr === 2 ? "white" : "black"}>
                                        <Text fw={700} fz={rem(14)}>Normal Mode</Text>
                                        <Text>Delivers highly efficient speech to text experience up to 80%.</Text>
                                    </Stack>
                                </Flex>
                            </Menu.Item>

                            <Menu.Item component="a" bg={asr === 1 ? "#5956E9" : "white"}>
                                <Flex onClick={() => setAsr(1)} align={"start"} className="item-container">
                                    <Stack spacing={rem(4)} c={asr === 1 ? "white" : "black"}>
                                        <Text fw={700} fz={rem(14)}>Boot Mode</Text>
                                        <Text>Using high technology helps improve voice recognition accuracy with up
                                            to 95% accuracy.
                                        </Text>
                                    </Stack>
                                </Flex>
                            </Menu.Item>
                        </>
                    </Menu.Dropdown>
                </Menu>
            </Flex>

            <Flex justify={"space-between"} className="w-100 dashboard-container" bg={"white"}>
                <Stack spacing={rem(40)} pt={rem(50)} pl={{base: rem(0), sm: rem(16), xl: rem(30)}} bg={"#2C2F33"}
                       className="left h-100" w={{base: "0%",md: "18%", xl: "18%"}} sx={{borderTopRightRadius: 30}}>
                    <Flex align={"center"}>
                        <IconBrandGoogleHome/> <Text ml={rem(12)}>Sindeso AI</Text>
                    </Flex>
                    {/*<Flex align={"center"}>*/}
                    {/*    <IconLanguageKatakana/> <Text ml={rem(12)}>Speech to text</Text>*/}
                    {/*</Flex>*/}
                    {/*<Flex align={"center"}>*/}
                    {/*    <IconLanguage/> <Text ml={rem(12)}>Translation</Text>*/}
                    {/*</Flex>*/}
                    {/*<Flex align={"center"}>*/}
                    {/*    <IconAi/> <Text ml={rem(12)}>Quick AI</Text>*/}
                    {/*</Flex>*/}
                </Stack>
                <Flex w={{base: "100%", md: "85%", xl: "82%"}} className="right" bg="white">
                    <Flex className="main-dashboard" w={"98%"} mx={"auto"}>
                        <SindesoAI asrProps={asr}></SindesoAI>
                    </Flex>
                </Flex>
            </Flex>
        </Box>
    );
};

export default Dashboard;
