/* eslint-disable no-restricted-imports */
import {AppShell, Button, Center} from "@mantine/core";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";


import {Header} from "./header";
import {Footer} from "./footer";
import {useDisclosure} from "../../hooks/useDisclosure";

interface MainLayoutProps {
    children: React.ReactNode;
}

export const UserLayout = ({children}: MainLayoutProps) => {
    const {isOpen: opened, toggle: toggleNavbar, close} = useDisclosure(false);
    const [hiddenAppShell, setHiddenAppShell] = useState<boolean>(true);
    const {t} = useTranslation();

    // const { data: user } = useUser();
    // const { data: roleData } = useRoleList();
    // const { setRoles } = useRoleStore();

    useEffect(() => {
        setHiddenAppShell(true)
    }, []);

    return (
        <AppShell hidden={hiddenAppShell} layout="alt" padding={0}
                  styles={{body: {backgroundColor: "black"}}}
        >
            <Button className={"d-none"} onClick={close}></Button>
            <Header
                opened={opened}
                // isHideAppShell={hiddenAppShell}
                // toggleAppShell={() => setHiddenAppShell(!hiddenAppShell)}
                toggleNavbar={toggleNavbar}
                title={t("SindesoTech")}
            />
            <Center>
                {children}
            </Center>
            <Footer opened={opened}
                    // isHideAppShell={hiddenAppShell}
                    // toggleAppShell={() => setHiddenAppShell(!hiddenAppShell)}
                    // toggleNavbar={toggleNavbar}
                    title={t("SindesoTech")}
            />
        </AppShell>
    );
};
