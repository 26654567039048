import React, {useEffect} from 'react';
import {Button, Center, createStyles, Flex, rem, Stack, Title} from "@mantine/core";
import './Login/loginStyles.scss';
import Background from "../../images/backgroundHome_Sindeso.png";
import {useNavigate} from "react-router-dom";
import {RoutePaths} from "../../routers/route-paths";

const useStyles = createStyles((theme) => ({
    container: {
        marginTop: rem(100),
        [theme.fn.smallerThan("sm")]: {
            marginTop: rem(50),
        }
    },
    textDescription: {
        textAlign: "center",
        fontSize: 20,
        [theme.fn.smallerThan("sm")]: {
            fontSize: 12,
        }
    }

}));

const UserHomePage = () => {
    const { classes } = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
    }, []);

    const handleClickGetStarted = () => {
        const auth = localStorage.getItem('accessToken');
        if (!auth) {
            navigate(RoutePaths.AUTH_LOGIN);
        }else {
            navigate(RoutePaths.DASHBOARD);
        }
    }

    const handleEcosystem = () => {
        navigate(RoutePaths.USER_SERVICES);
    }

    return (
        <Stack className="w-100" bg={"black"}>
            <Center className={classes.container}>
                <Stack spacing={rem(0)}>
                    <Center>
                        <Title fz={{sm: rem(70)}}  className="text-world" sx={{}}
                               bg="linear-gradient(to right, #FF3BFF, #ECBFBF, #5C24FF, #D94FD5)">
                            An intelligent Assistant
                        </Title>
                    </Center>
                    <Center fw={700}>
                        <Title fz={{sm: rem(70)}} c={"white"}>
                            Sindeso Tech
                        </Title>
                    </Center>
                    <Center fz={rem(20)} c={"white"} className={classes.textDescription}>
                        Quickly and accurately convert your voice into text
                        an translate immediately
                    </Center>
                </Stack>

            </Center>
            <Stack mb={rem(200)} mx={"auto"} mih={rem(500)} w={"90%"} style={{
                backgroundImage: `url(${Background})`,
                backgroundSize: '100%',
                overflowX: "hidden",
                backgroundRepeat: "no-repeat"
            }}>
                <Flex align={"center"} mx={"auto"}>
                    <Button onClick={handleClickGetStarted} bg={"black"}  px={rem(20)} mih={rem(50)} radius={25} variant={"outline"} mr={rem(12)}  c={"white"}>Get Started</Button>
                    <Button onClick={handleEcosystem} bg={"black"} px={rem(20)} mih={rem(50)} radius={26} variant={"outline"} color={"white"} c={"white"}>Ecosystems</Button>
                </Flex>

            </Stack>
        </Stack>
    );
};

export default UserHomePage;
