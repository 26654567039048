import { MantineThemeOverride } from "@mantine/core";

export const theme: MantineThemeOverride = {
  colorScheme: "dark",
  globalStyles: (theme) => ({
    body: {
      ...theme.fn.fontStyles(),
    },

    "button:focus": {
      outline: "none",
    },
  }),
  fontFamily: "Roboto",
  colors: {
    button: [
      "#EBEFFF",
      "#D3DBFE",
      "#A5B3F6",
      "#7B8FF0", //main
      "#4b66e9",
      "#324fe6",
      "#2244e6",
      "#1436cd",
      "#0c2fb8",
      "#0028a3",
    ],
    box: [
      "#f4f4f6",
      "#e5e5e5",
      "#c9c9cb",
      "#aaabb1",
      "#91929c",
      "#808190",
      "#78798a",
      "#666777",
      "#5a5b6c",
      "#333440",
    ],
    titleAdmin: ["#7F869A", "#7B8FF0"],
  },
  breakpoints: {
    xs: "36em", //576px
    sm: "48em", //768px
    md: "62em", //992px
    lg: "75em", //1200px
    xl: "88em", //1408px
  },
};
