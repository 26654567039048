import {RoutePaths} from "../route-paths";
import {UserOutlined, MailOutlined, AuditOutlined} from '@ant-design/icons';
import {LinksGroupProps} from "../../components/MainLayout/Navbar";


export const getNavigation = (): LinksGroupProps[] => [
  {
    label: "Users",
    icon: UserOutlined,
    iconColor: "yellow",
    link: RoutePaths.ADMIN_USER_LIST,
  },
  {
    label: "News",
    icon: MailOutlined,
    iconColor: "yellow",
    link: RoutePaths.ADMIN_POST_LIST,
  },
  {
    label: "Contacts",
    icon: AuditOutlined,
    iconColor: "yellow",
    link: RoutePaths.ADMIN_REPORTED_POST_LIST,
  },
];
