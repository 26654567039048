import {Flex, Loader} from "@mantine/core";
import {Suspense} from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";

import {AdminUserRoutes} from "./module/adminRouter";
import {RoutePaths} from "./route-paths";
import {MainLayout} from "../components/MainLayout";
import {DashboardRouter} from "./module/dashboardRouter";
import {PaymentRouter} from "./module/paymentRouter";
// import {lazyImport} from "./lazyImport";

const AppLayout = () => {
    const auth = localStorage.getItem('accessToken');

    const location = useLocation();
    if (!auth) {
        return <Navigate to={RoutePaths.AUTH_LOGIN} state={{from: location}} replace/>;
    }

    return (
        <MainLayout>
            <Suspense
                fallback={
                    <Flex align={"center"} justify={"center"} w={"100%"} h={"100%"}>
                        <Loader variant="dots"/>
                    </Flex>
                }
            >
                <Outlet/>
            </Suspense>
        </MainLayout>
    );
};


const DashboardLayout = () => {
    const auth = localStorage.getItem('accessToken');

    const location = useLocation();
    if (!auth) {
        return <Navigate to={RoutePaths.AUTH_LOGIN} state={{from: location}} replace/>;
    }

    return (
        <Suspense
            fallback={
                <Flex align={"center"} justify={"center"} w={"100%"} h={"100%"}>
                    <Loader variant="dots"/>
                </Flex>
            }
        >
            <Outlet/>
        </Suspense>
    );
};

export const protectedRoutes = [
    {
        path: "/admin",
        element: <AppLayout/>,
        children: [
            /* Start: App Routes */
            {path: "user/*", element: <AdminUserRoutes/>},
            /* End: App Routes */
            {index: true, element: <Navigate to={RoutePaths.NOT_FOUND} replace/>},
            {path: "*", element: <Navigate to={RoutePaths.NOT_FOUND} replace/>},
        ],
    },
    {
        path: "/dashboard",
        element: <DashboardLayout/>,
        children: [
            /* Start: App Routes */
            {path: "control/*", element: <DashboardRouter/>},
            /* End: App Routes */
            {index: true, element: <Navigate to={RoutePaths.NOT_FOUND} replace/>},
            {path: "*", element: <Navigate to={RoutePaths.NOT_FOUND} replace/>},
        ],
    },
    {
        path: "/payment",
        element: <DashboardLayout/>,
        children: [
            /* Start: App Routes */
            {path: "method/*", element: <PaymentRouter/>},
            /* End: App Routes */
            {index: true, element: <Navigate to={RoutePaths.NOT_FOUND} replace/>},
            {path: "*", element: <Navigate to={RoutePaths.NOT_FOUND} replace/>},
        ],
    },
];
