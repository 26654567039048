// AudioRecordingService.js
import axios from "axios";

class AzureOutputService {
  constructor(
    speechLang,
    outputLang,
    translationList,
    setTranslationList,
    setStartTra,
    revertText
  ) {
    this.keyAzure = process.env.REACT_APP_AZURE_SPEECH_KEY;
    this.region = process.env.REACT_APP_AZURE_SPEECH_REGION;
    this.speechLang = speechLang;
    this.outputLang = outputLang;
    this.translationList = translationList;
    this.setTranslationList = setTranslationList;
    this.setStartTra = setStartTra;
    this.revertText = revertText;
  }

  startTranslating() {
    // eslint-disable-next-line array-callback-return
    this.translationList.map((e) => {
      if (!e.resultTranslator && !e.error) {
        const text = e.recognizedText;
        const fromLang = e.asr;
        const toLang = e.tra;
        if (fromLang === toLang) {
          this.settingResult(e, text);
        } else {
          axios
            .post(
              `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=${fromLang}&to=${toLang}`,
              [{ text }],
              {
                headers: {
                  "Content-Type": "application/json",
                  "Ocp-Apim-Subscription-Key": this.keyAzure,
                  "Ocp-Apim-Subscription-Region": this.region,
                },
              }
            )
            .then((res) => {
              this.settingResult(e, res.data[0].translations[0].text);
            });
        }

      }
      if (e.error) {
        this.settingResult(e, null);
      }
    });
  }

  settingResult(e, result) {
    this.setTranslationList((prevTranslationList) => [
      ...prevTranslationList.map((tran) => {
        if (tran.id === e.id && !tran.resultTranslator && tran.status === 1) {
          this.setStartTra(0);
          return {
            ...tran,
            resultTranslator: result,
            revertResultTranslator: !result ? result : this.revertText(result),
            status: 1,
          };
        }
        return tran;
      }),
    ]);
  }

}

export default AzureOutputService;
