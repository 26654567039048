import React, {useEffect, useState} from 'react';
import './loginStyles.scss';
import {useLocation, useNavigate} from "react-router-dom";
import {Center, Text, createStyles, rem, Button, Stack} from "@mantine/core";
import {cn, findError} from "../../../util/misc";
import {AuthResendVerify, AuthVerify} from "../../../api/auth/login";
import {RoutePaths} from "../../../routers/route-paths";
import {notifyError, notifySuccess} from "../../../util/notification";
import {useTranslation} from "react-i18next";

const useStyles = createStyles((theme) => ({
    mainLayout: {
        textAlign: "center",
        borderRadius: 8,
        color: "white",
        padding: rem(48),
        background: "#333440",
        maxWidth: rem(500),
        [theme.fn.smallerThan("sm")]: {
            padding: rem(20),
        }

    },

    buttonDisable: {
        '&:disabled': {
            backgroundColor: "#4B4C58",
        }
    }
}));

const VerifyEmail = () => {
    const [token, setToken] = useState('');
    const [email, setEmail] = useState('');
    const {t} = useTranslation();
    const [time, setTime] = useState(300);
    const [statusVerify, setStatusVerify] = useState("");
    const navigate = useNavigate();
    const {classes} = useStyles();

    const location = useLocation();

    useEffect(() => {
        let timer: string | number | NodeJS.Timeout | undefined;
        timer = setInterval(() => {
            setTime((prevTime) => {
                if (prevTime > 0) {
                    return prevTime - 1;
                } else {
                    clearInterval(timer);
                    return 0;
                }
            });
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tokenFromUrl = queryParams.get('token');
        const emailFromUrl = queryParams.get('email');

        if (tokenFromUrl && emailFromUrl) {
            setToken(tokenFromUrl);
            setEmail(emailFromUrl);
            AuthVerify(tokenFromUrl).then(() => {
                setStatusVerify("success")
            }).catch(() => {
                setTime(300)
                setStatusVerify("expired")
            })
        }
    }, [location]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };


    const resendEmail = () => {
        AuthResendVerify(email).then(() => {
            notifySuccess({message: t("Email has been sent successfully")});
        }).catch(err => {
            notifyError({message: t(findError(err))});
        });
    }

    return (
        <>
            {token &&
                <Center className={cn("vh-100")}>
                    {statusVerify === "success" &&
                        <Stack className={cn(classes.mainLayout, "")}>
                            <Text fw={700} fz={rem(30)}>Email Has Been Verified</Text>
                            <Text c={"#8589A1"}>The email address has already been verified successfully.
                                Click the button below to direct to home page to log in.</Text>

                            <Button
                                className={cn(classes.buttonDisable, "")}
                                fz={rem(18)}
                                radius={8}
                                mt={{base: rem(15), xl: rem(30)}}
                                bg="#5956E9"
                                h={rem(48)}
                                onClick={() => navigate(RoutePaths.AUTH_LOGIN)}
                            >
                                Login
                            </Button>
                        </Stack>
                    }
                    {statusVerify === "expired" &&
                        <Stack className={cn(classes.mainLayout, "")}>
                            <Text fw={700} fz={rem(30)}>Confirmation Link Expired</Text>
                            <Text c={"#8589A1"}>The verification link has expired. To verify your email address, please
                                find the latest verification email in your mailbox.</Text>

                            <Button
                                className={cn(classes.buttonDisable, "")}
                                fz={rem(18)}
                                radius={8}
                                mt={{base: rem(15), xl: rem(30)}}
                                bg="#5956E9"
                                h={rem(48)}
                                disabled={time > 0}
                                onClick={() => resendEmail}
                            >
                                Resend verification
                            </Button>
                            {time > 0 &&
                                <Text>{formatTime(time)}</Text>
                            }
                        </Stack>
                    }
                </Center>
            }
            {!token &&
                <Center className={cn("vh-100")}>
                    <Stack className={cn(classes.mainLayout, "")}>
                        <Text fw={700} fz={rem(30)}>Thank You For Registering</Text>
                        <Text c={"#8589A1"}>An email has been sent to activate your account. Please click the link to
                            activate your
                            account.</Text>

                        <Button
                            className={cn(classes.buttonDisable, "")}
                            fz={rem(18)}
                            radius={8}
                            mt={{base: rem(15), xl: rem(30)}}
                            bg="#5956E9"
                            h={rem(48)}
                            disabled={time > 0}
                            onClick={() => resendEmail}
                        >
                            Resend verification
                        </Button>
                        {time > 0 &&
                            <Text>{formatTime(time)}</Text>
                        }
                    </Stack>
                </Center>
            }
        </>
    );
};

export default VerifyEmail;
