import {ResponseListAdmin, News, ResponseDataUser} from "./types";
import { del, get, post, put } from '../index';

interface QueryParams {
    page?: number;
    limit?: number;
    search?: string;
    author?: string;
}

const NewsList = async (queryParams?: QueryParams): Promise<ResponseListAdmin<News>> => {
    let url = '/News/admin/new-list/';

    const params: Record<string, string> = {};
    if (queryParams) {
        Object.entries(queryParams).forEach(([key, value]) => {
            if (value !== "") {
                params[key] = value;
            }
        });
    }

    if (Object.keys(params).length > 0) {
        const queryString = new URLSearchParams(params).toString();
        url += `?${queryString}`;
    }
    return await get(url);
};

const NewsUserList = async (queryParams?: QueryParams): Promise<ResponseListAdmin<News>> => {
    let url = '/news/user/news-list/';

    const params: Record<string, string> = {};
    if (queryParams) {
        Object.entries(queryParams).forEach(([key, value]) => {
            if (value !== "") {
                params[key] = value;
            }
        });
    }

    if (Object.keys(params).length > 0) {
        const queryString = new URLSearchParams(params).toString();
        url += `?${queryString}`;
    }
    return await get(url);
};
const newsUpdate = async (id: number | undefined, status: string): Promise<ResponseListAdmin<News>> => {
    let url = `/news/admin/new-update/${id}`;
    const data = { status };
    return await put(url, data);
};
const newDelete = async (id: number | undefined): Promise<ResponseListAdmin<News>> => {
    let url = `/news/admin/new-del/${id}`;
    return await del(url);
};

const newDetail = async (id: string | undefined): Promise<ResponseDataUser<News>> => {
    let url = `/news/${id}`;
    return await get(url);
};

const newsCreate = async (body: any): Promise<ResponseListAdmin<News>> => {
    let url = `/news/create`;
    return await post(url, body);
};

const likeNews = async (id: number): Promise<ResponseListAdmin<News>> => {
    let url = `/news/likes/${id}`;
    return await post(url, {});
};

const viewNews = async (id: number): Promise<ResponseListAdmin<News>> => {
    let url = `/news/view/${id}`;
    return await post(url, {});
};


export const NewsService = {
    NewsList,
    NewsUserList,
    newsUpdate,
    newDelete,
    newsCreate,
    likeNews,
    viewNews,
    newDetail
};
