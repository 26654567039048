import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  navbar: {
    padding: rem(0),

    [theme.fn.smallerThan("sm")]: {
      padding: `${rem(12)} ${rem(16)}`,
    },
  },

  header: {
    marginBottom: 0,
    paddingBottom: rem(12),

    [theme.fn.largerThan("sm")]: {
      marginBottom: rem(48),
      paddingLeft: rem(12),
      paddingRight: rem(12),
      borderTop: "none",
    },
  },

  logo: {
    width: rem(80),
    height: rem(30),

    [theme.fn.largerThan("sm")]: {
      width: "100%",
      height: "auto",
    },
  },

  divider: {
    color: theme.colors.gray[2],
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      padding: `${rem(30)} 0`,
    },
  },

  linksInner: {
    fontSize: 16,
  },

  footer: {
    borderTop: `${rem(1)} solid ${theme.colors.gray[2]}`,
    paddingTop: rem(24),
  },
}));
