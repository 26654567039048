import {
    Burger,
    Header as MantineHeader,
    HeaderProps as MantineHeaderProps,
    createStyles,
    rem,
    Text, Flex, Button, Image, Title, Menu, Drawer, Stack
} from "@mantine/core";

import React, {useEffect} from "react";
// import {useTranslation} from "react-i18next";
import {cn} from "../../util/misc";
import {handleNavigate} from "../../utils";
import {useLocation, useNavigate} from "react-router-dom";
import {RoutePaths} from "../../routers/route-paths";
import logo from "../../images/sindesoLogo.png";
import {IconExternalLink, IconLogout, IconUserSquareRounded} from "@tabler/icons-react";
import {useUserStore} from "../../store/useUserStore";

const useStyles = createStyles((theme) => ({
    header: {
        backgroundColor: "black",
        border: "none",
        [theme.fn.largerThan("lg")]: {
            backgroundColor: "black",
            padding: `${rem(0)} ${rem(100)} ${rem(0)} ${rem(100)}`,
        },
    },
    logo: {
        width: rem(80),
        height: rem(30),

        [theme.fn.largerThan("lg")]: {
            width: "100%",
            height: "auto",
        },
    },
    navigationText: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        ":hover": {
            color: "#9D5CE9"
        }
    }
}));

interface HeaderProps extends Partial<MantineHeaderProps> {
    opened: boolean;
    toggleNavbar: VoidFunction;
    toggleAppShell?: VoidFunction;
    isHideAppShell?: boolean;
    title: string;
}

export const Header = ({opened, toggleNavbar, className, ...otherProps}: HeaderProps) => {
    // const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation().pathname;
    const {classes} = useStyles();
    const {user, fetchUser} = useUserStore();

    const handleLoggout = () => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("email");
        fetchUser().then();
    }

    useEffect(() => {
            fetchUser().then();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [navigate]);


    return (
        <MantineHeader
            height={{base: 60, sm: rem(100)}}
            className={cn(classes.header, className)}
            {...otherProps}
        >
            {/*Mobile here*/}
            <Flex align={"center"} justify={"space-between"} className="d-lg-none h-100 px-3">
                <Burger opened={opened} onClick={toggleNavbar} size="sm"/>
                <Drawer.Root opened={opened} onClose={toggleNavbar}>
                    <Drawer.Overlay/>
                    <Drawer.Content>
                        <Drawer.Header>
                            <Drawer.Title></Drawer.Title>
                            <Drawer.CloseButton/>
                        </Drawer.Header>
                        <Drawer.Body>
                            <Stack>
                                <Text className={cn(classes.navigationText, className)}
                                      c={"" === location ? '#9D5CE9' : 'white'}
                                      onClick={() => {
                                          handleNavigate(navigate, '');
                                          toggleNavbar();
                                      }}>Home</Text>
                                <Text className={cn(classes.navigationText, className)}
                                      c={RoutePaths.USER_CONTACT === location ? '#9D5CE9' : 'white'}
                                      onClick={() => {
                                          handleNavigate(navigate, RoutePaths.USER_CONTACT);
                                          toggleNavbar();
                                      }}>Contacts</Text>
                                <Text className={cn(classes.navigationText, className)}
                                      c={RoutePaths.USER_SERVICES === location ? '#9D5CE9' : 'white'}
                                      onClick={() => {
                                          handleNavigate(navigate, RoutePaths.USER_SERVICES);
                                          toggleNavbar();
                                      }}>Service</Text>
                                <Text className={cn(classes.navigationText, className)}
                                      c={RoutePaths.USER_SOLUTIONS === location ? '#9D5CE9' : 'white'}
                                      onClick={() => {
                                          handleNavigate(navigate, "");
                                          toggleNavbar();
                                      }}>Solutions</Text>
                                <Text className={cn(classes.navigationText, className)}
                                      c={RoutePaths.USER_NEWS === location ? '#9D5CE9' : 'white'}
                                      onClick={() => {
                                          handleNavigate(navigate, RoutePaths.USER_NEWS);
                                          toggleNavbar();
                                      }}>News</Text>
                            </Stack>
                        </Drawer.Body>
                    </Drawer.Content>
                </Drawer.Root>
                <Text sx={{cursor: "pointer"}} onClick={() => handleNavigate(navigate, RoutePaths.USER_HOME)}>
                    <Image src={logo} width={30} height={30}/>
                </Text>
                <Menu width={200} shadow="md">
                    <Menu.Target>
                        <IconUserSquareRounded/>
                    </Menu.Target>

                    <Menu.Dropdown>
                        {user ? (
                            <>
                                <Menu.Item
                                    icon={<IconExternalLink size={rem(14)}/>}
                                    component="a"
                                    onClick={() => handleNavigate(navigate, RoutePaths.DASHBOARD)}>
                                    Dashboard
                                </Menu.Item>

                                <Menu.Item
                                    icon={<IconLogout size={rem(14)}/>}
                                    component="a"
                                    target="_blank"
                                    onClick={handleLoggout}
                                >
                                    Log out
                                </Menu.Item>
                            </>
                        ) : (
                            <>
                                <Menu.Item
                                    icon={<IconExternalLink size={rem(14)}/>}
                                    component="a"
                                    onClick={() => handleNavigate(navigate, RoutePaths.AUTH_LOGIN)}>
                                    Sign in
                                </Menu.Item>

                                <Menu.Item
                                    icon={<IconExternalLink size={rem(14)}/>}
                                    component="a"
                                    target="_blank"
                                    onClick={() => handleNavigate(navigate, RoutePaths.AUTH_REGISTER)}
                                >
                                    Sign up
                                </Menu.Item>
                            </>
                        )}

                    </Menu.Dropdown>
                </Menu>
            </Flex>

            <Flex className="position-relative h-100 d-none d-lg-flex align-items-center justify-content-between"
                  mih={rem(100)}>
                <Flex align={"center"} sx={{cursor: "pointer"}}
                      onClick={() => handleNavigate(navigate, RoutePaths.USER_HOME)}>
                    <Image src={logo} width={30} height={30}/>
                    <Title ml={rem(10)} c={"white"}>Sindeso Tech</Title>
                </Flex>
                <Flex className="position-absolute h-100" sx={{left: "50%", transform: "translateX(-50%)"}} c={"white"}
                      fz={rem(16)} gap={rem(16)}>
                    <Text className={cn(classes.navigationText, className)}
                          c={"" === location ? '#9D5CE9' : 'white'}
                          onClick={() => handleNavigate(navigate, '')}>Home</Text>
                    <Text className={cn(classes.navigationText, className)}
                          c={RoutePaths.USER_CONTACT === location ? '#9D5CE9' : 'white'}
                          onClick={() => handleNavigate(navigate, RoutePaths.USER_CONTACT)}>Contacts</Text>
                    <Text className={cn(classes.navigationText, className)}
                          c={RoutePaths.USER_SERVICES === location ? '#9D5CE9' : 'white'}
                          onClick={() => handleNavigate(navigate, RoutePaths.USER_SERVICES)}>Service</Text>
                    <Text className={cn(classes.navigationText, className)}
                          c={RoutePaths.USER_SOLUTIONS === location ? '#9D5CE9' : 'white'}
                          onClick={() => handleNavigate(navigate, "")}>Solutions</Text>
                    <Text className={cn(classes.navigationText, className)}
                          c={RoutePaths.USER_NEWS === location ? '#9D5CE9' : 'white'}
                          onClick={() => handleNavigate(navigate, RoutePaths.USER_NEWS)}>News</Text>
                </Flex>
                <Flex>
                    {user ? ( // If logged in, show user icon and logout button
                        <Flex>
                            <Menu width={200} shadow="md">
                                <Menu.Target>
                                    <IconUserSquareRounded style={{cursor: "pointer"}}/>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    <Menu.Item
                                        icon={<IconExternalLink size={rem(14)}/>}
                                        onClick={() => handleNavigate(navigate, RoutePaths.DASHBOARD)}>
                                        Dashboard
                                    </Menu.Item>

                                    <Menu.Item
                                        icon={<IconLogout size={rem(14)}/>}
                                        component="a"
                                        onClick={handleLoggout}
                                        target="_blank"
                                    >
                                        Log out
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        </Flex>
                    ) : ( // If not logged in, show login and signup buttons
                        <Flex>
                            <Button c="#F8F7F7" variant="subtle" size="sm"
                                    onClick={() => handleNavigate(navigate, RoutePaths.AUTH_LOGIN)}
                            >Sign in</Button>
                            <Button c="#F8F7F7" sx={{borderColor: "#F8F7F7"}} variant="outline" size="sm"
                                    onClick={() => handleNavigate(navigate, RoutePaths.AUTH_REGISTER)}>Sign
                                Up</Button>
                        </Flex>
                    )}
                </Flex>
            </Flex>

        </MantineHeader>
    );
};
